<template>
    <div>
        <snackbar-component :snackbar.sync="snackbar" :texto="texto" :estado="estado"></snackbar-component>
        <titular-form ref="titularRef"></titular-form>
        <proyecto-form ref="proyectoRef"></proyecto-form>
        <compromiso-form ref="compromisoRef"></compromiso-form>
        <contacto-form ref="contactoRef"></contacto-form>
        <visita-form ref="visitaRef"></visita-form>
        <formulario-mantenedor-component ref="formMantenedorRef"></formulario-mantenedor-component>
        <ejecutar-visita ref="ejecutarVisitaRef"></ejecutar-visita>
        <bitacora-form ref="bitacoraRef"></bitacora-form>
        <dialog-yes-no-component ref="dialogYesNoRef" :width="400"></dialog-yes-no-component>
        <nuevo-evento ref="eventoRef"></nuevo-evento>
        <documentos-form ref="documentoFormRef"></documentos-form>
        <dialog-view-document ref="viewDocumentRef"></dialog-view-document>
        <DialogComponent ref="dialogComponentRef"></DialogComponent>
        <UsuarioTitularForm ref="usuarioTitularForm"></UsuarioTitularForm>
        <template v-if="isSearch">
            <v-row>
                <v-col sm="4" class="mb-4">
                    <v-text-field
                        v-if="labelSearch"
                        append-icon="mdi-magnify" 
                        :label="labelSearch"
                        hide-details
                        outlined
                        dense
                        v-model="searchInput"
                        v-on:keyup="handleSearchName"
                    ></v-text-field>
                </v-col>
                <v-col cols="1" style="max-width: 3%" v-if="isSeleccionColumnas">
                    <v-menu v-model="menuOpen" bottom left>
                        <template v-slot:activator="{ on: menu, attrs }">
                            <v-tooltip top>
                                <template v-slot:activator="{ on: tooltip }">
                                    <v-btn dark icon v-bind="attrs" v-on="{ ...tooltip, ...menu }" color="black">
                                        <v-icon>mdi-tune</v-icon>
                                    </v-btn>
                                </template>
                                <span>Seleccionar columnas</span>
                            </v-tooltip>
                        </template>
                        <div style="max-height: 300px; overflow-y: auto; background-color: white;">
                            <v-list>
                                <v-list-item v-for="header in headers" :key="header.value">
                                    <v-checkbox
                                        v-if="((header.value == 'estado-editable' && view == 'ejecuta-visita') || (header.value == 'observacion-editable' && view == 'ejecuta-visita') || (header.value == 'plan_accion-editable' && view == 'ejecuta-visita') || (header.value == 'actions' && view == 'ejecuta-visita'))"
                                        disabled
                                        v-model="selectedHeaders"
                                        :label="header.text"
                                        :value="header.value"
                                        @click="seleccionColumnas" 
                                        style="white-space: nowrap; margin-right: 16px;"
                                    ></v-checkbox>
                                    <v-checkbox
                                        v-else
                                        v-model="selectedHeaders"
                                        :label="header.text"
                                        :value="header.value"
                                        @click="seleccionColumnas"
                                        style="white-space: nowrap; margin-right: 16px;"
                                    ></v-checkbox>
                                </v-list-item>
                            </v-list>
                            <v-btn @click="closeMenu" dark text color="primary">Cerrar</v-btn>
                        </div>
                    </v-menu>
                </v-col>
                <v-col v-if="exportExcel" style="max-width: 3%">
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn dark icon color="black" @click="exportToExcel" v-bind="attrs" v-on="on">
                                <v-icon>mdi-file-excel</v-icon>
                            </v-btn>
                        </template>
                        <span>Exportar a Excel</span>
                    </v-tooltip>
                </v-col>
                <v-col v-if="view == 'visita' || view == 'proyecto'">
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn dark icon color="black" @click="toggleFilter" v-bind="attrs" v-on="on">
                                <v-icon>mdi-filter</v-icon>
                            </v-btn>
                        </template>
                        <span>Mostrar filtros</span>
                    </v-tooltip>
                </v-col> 
                <v-col v-if="view == 'compromiso' || view == 'ficha-compromiso' || view == 'compromiso-visita'" style="max-width: 3%">
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn dark icon color="black" @click="toggleFilterCompromisos" v-bind="attrs" v-on="on">
                                <v-icon>mdi-filter</v-icon>
                            </v-btn>
                        </template>
                        <span>Mostrar filtros</span>
                    </v-tooltip>
                </v-col>
                <v-col v-if="view == 'compromiso' && puedeEliminarCompromiso" style="max-width: 3%">
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn dark icon color="black" @click="seleccion = !seleccion" v-bind="attrs" v-on="on">
                                <v-icon>mdi-checkbox-marked-outline</v-icon>
                            </v-btn>
                        </template>
                        <span>Seleccionar compromisos</span>
                    </v-tooltip>
                </v-col>
                <v-col v-if="(view == 'compromiso') && showDeleteButton">
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn dark icon color="black" @click="eliminarSeleccionCompromisos" v-bind="attrs" v-on="on">
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </template>
                        <span>Eliminar compromisos</span>
                    </v-tooltip>
                </v-col>
                <v-col v-if="view === 'compromiso-visita'" class="text-end">
                    <h4>Compromisos Seleccionados: {{ selected.length }}</h4>
                </v-col>
            </v-row>
            <v-row class="mb-1" v-if="view == 'proyecto' && mostrarFiltros || view == 'visita' && mostrarFiltros">
                <v-col>
                    <select-component label="Titular" ref="sTitular" url="/titulares" :valor="titular" :clearable="true" chips></select-component>
                </v-col> 
                <v-col v-if="view == 'visita'">
                    <select-component label="Unidad Fiscalizable" ref="sProyecto" url="/proyectos" :valor="proyecto" chips></select-component>
                </v-col> 
                <v-col>
                    <v-btn @click="filtros">Buscar</v-btn>
                </v-col>
            </v-row> 
            <v-row class="mb-1" v-if="view == 'compromiso' && mostrarFiltrosCompromisos || view == 'ficha-compromiso' && mostrarFiltrosCompromisos || view == 'compromiso-visita' && mostrarFiltrosCompromisos">
                <v-col v-if="perfilStore.decryptData(perfil_id) != perfilStore.titular && perfilStore.decryptData(perfil_id) != perfilStore.encargadoTitular">
                    <v-combobox
                        v-model="registroSeleccionado"
                        :items="registro"
                        label="Registro"
                        dense
                        @change="changeRegistro"
                        clearable
                        chips
                    ></v-combobox>
                </v-col>
                <v-col cols="4" class="mb-5">
                    <select-component label="Verificador" ref="sVerificador" url="/verificadores" :valor="verificador" :clearable="true" chips></select-component>
                </v-col> 
                <v-col cols="4" class="mb-5">
                    <select-component label="Componente" ref="sComponente" url="/componentes" :valor="componente" :clearable="true" chips></select-component>
                </v-col> 
                <v-col cols="4" class="mb-5">
                    <select-component label="Fase" ref="sFase" url="/fases" :valor="fase" :clearable="true" chips></select-component>
                </v-col> 
                <v-col cols="4" class="mb-5">
                    <select-component label="Frecuencia" ref="sFrecuenciaSugerida" url="/frecuencias" :valor="frecuenciaSugerida" :clearable="true" chips></select-component>
                </v-col> 
                <v-col cols="4" class="mb-5">
                    <select-component label="Estado" ref="sEstadoCompromiso" url="/estados/categoria/C" :valor="estadoCategoria" :clearable="true" chips :isFilter="true"></select-component>
                </v-col> 
                <v-col cols="4" class="mb-5">
                    <v-btn @click="filtrosCompromisos">Buscar</v-btn>
                </v-col>
            </v-row> 
        </template>
        <v-data-table
            :headers="headers.filter(header => selectedHeaders.includes(header.value))"
            :items="items"
            :search="searchInput"
            :loading="loading"
            loading-text="Cargando... Por favor espere"
            no-data-text="Sin registros encontrados"
            class="elevation-5 my-table"
            :show-select="seleccion"
            v-model="selected"
            :item-key="itemKey"
            :key="keyTable"
            fixed-header
            :height="items.length == 0 ? '' : tableHeight"
            :footer-props="{
                'items-per-page-text':'Registros por página',
                'items-per-page-all-text': 'Todos',
                'disable-items-per-page': disbleItemsPerPage,
                'disable-pagination': disablePagination
            }"
            ref="tablaGeneralRef"
            :itemsPerPage="rowsPerPage"
            @input="onSelectionChange"
        >
            <!-- <template v-for="header in headers" v-slot:[`header.${header.value}`]="{ header }">
                <template>
                    <label @click="filtroColumna(header.value)">{{ header.text }}</label>
                </template>
            </template>
            <template v-slot:body.prepend v-if="mostrarfiltroColumna">
                <tr>
                    <template v-for="(header, index) in headers">
                        <td>
                            <v-text-field
                                v-if="header.filtro == true"
                                :placeholder="header.text.toLowerCase()"
                                dense
                                v-model="filtroColumnas[header.value]"
                                @input="filtrarPorColumna(header.value, filtroColumnas[header.value], index)"
                                :ref="'filtro'+index"
                            ></v-text-field>
                        </td>
                    </template>
                </tr>
            </template>-->
            <template v-slot:header.data-table-select v-if="view == 'compromiso'">
                <!-- Puedes dejarlo vacío para ocultar el checkbox -->
                <v-checkbox
                    v-model="allSelected"
                    @change="toggleAllSelection"
                    :indeterminate="isIndeterminate"
                    color="primary"
                />
            </template>
            <template v-slot:body="{ items }" v-if="view == 'compromiso' && (perfilStore.decryptData(perfil_id) == perfilStore.administrador || perfilStore.decryptData(perfil_id) == perfilStore.jefatura || perfilStore.decryptData(perfil_id) == perfilStore.analista)">
                <template v-if="loading">
                    <tr>
                        <td colspan="100%" class="loading-text">
                            Cargando... Por favor espere
                        </td>
                    </tr>
                </template>
                <template v-else>
                    <draggable 
                        tag="tbody" 
                        :list="items" 
                        @change="actualizarCodigo(items)" 
                        :key="keyDraggable"
                        handle=".grab"
                        :selected.sync="selectedDragItems"
                    >
                        <tr v-for="item in items" :key="item.id" :class="{ selected: item.isSelected }">
                            <td v-if="seleccion">
                                <v-checkbox
                                v-model="item.isSelected"
                                @change="toggleCheckboxDrag(item)"
                                :value="item.isSelected"
                                />
                            </td>
                            <td v-if="debeMostrarColumna('')">
                                <v-icon class="grab">
                                    mdi-drag
                                </v-icon>
                            </td>
                            <td v-if="debeMostrarColumna('semaforo')">
                                <div v-html="getSemaforo(item)"></div>
                            </td>
                            <td v-if="debeMostrarColumna('actions')">
                                <v-tooltip bottom v-if="!disabled & ((view != 'ejecuta-visita' && view != 'ficha-evento' && view != 'ficha-visita' && view != 'ficha-instrumento' && view != 'ficha-bitacora' && view != 'ficha-informe' && view != 'visita' && view != 'ficha-historico' && view != 'compromiso-documento' && view != 'visita-informe' && view != 'asignacion' && view != 'asignacion-titular' && view != 'ficha-documentos') || (view == 'visita' && item.estado.id != 17))">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon
                                            small
                                            class="mr-6"
                                            v-bind="attrs"
                                            v-on="on"
                                            @click="editViewItem(item, false)"
                                        >
                                            mdi-pencil
                                        </v-icon>
                                    </template>
                                    <span>Editar</span>
                                </v-tooltip>
                                <v-tooltip bottom v-if="!disabled && (view == 'compromiso' && puedeEliminarCompromiso)">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon
                                            small
                                            class="mr-6"
                                            v-bind="attrs"
                                            v-on="on"
                                            @click="deleteItem(item, true)"
                                        >
                                            mdi-delete
                                        </v-icon>
                                    </template>
                                    <span>Eliminar</span>
                                </v-tooltip>
                                <v-tooltip bottom v-if="((view != 'ficha-instrumento' && view != 'ejecuta-visita' && view != 'ficha-historico' && view != 'compromiso-documento' && view != 'asignacion' && view != 'asignacion-titular' && view != 'ficha-documentos') || ( view == 'ficha-historico' && item.visita.nombre))">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon
                                            small
                                            class="mr-6"
                                            v-bind="attrs"
                                            v-on="on"
                                            @click="editViewItem(item, true)"
                                        >
                                            mdi-eye
                                        </v-icon>
                                    </template>
                                    <span>Ver</span>
                                </v-tooltip>
                                <v-tooltip bottom v-if="!disabled && (view == 'compromiso')">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                        small
                                        class="mr-6"
                                        v-bind="attrs"
                                        v-on="on"
                                        @click="copyItem(item)"
                                    >
                                        mdi-content-copy
                                    </v-icon>
                                </template>
                                <span>Copiar Compromiso</span>
                            </v-tooltip>
                            </td>
                            <td v-if="debeMostrarColumna('codigo')">
                                {{ item.codigo }}
                            </td>
                            <td v-if="debeMostrarColumna('nombre')">
                                {{ item.nombre }}
                            </td>
                            <td v-if="debeMostrarColumna('descripcion')">
                                <div 
                                    v-html="resaltarPalabra(item.descripcion)"
                                    class="scrollable-div"
                                >
                                </div>
                            </td>
                            <td v-if="debeMostrarColumna('origen')">
                                {{ item.origen }}
                            </td>
                            <td v-if="debeMostrarColumna('norma')">
                                {{ item.norma }}
                            </td>
                            <td v-if="debeMostrarColumna('verificador')">
                                <template v-for="(verificador, index) in item.verificador">
                                    {{ item.verificador[index].nombre }}
                                    <br>
                                </template>
                            </td>
                            <td v-if="debeMostrarColumna('descripcion_verificador')">
                                {{ item.descripcion_verificador }}
                            </td>
                            <td v-if="debeMostrarColumna('componente')">
                                <template v-for="(componente, index) in item.componente">
                                    {{ item.componente[index].nombre }}
                                    <br>
                                </template>
                            </td>
                            <td v-if="debeMostrarColumna('fase.nombre')">
                                {{ item.fase.nombre }}
                            </td>
                            <!-- <td v-if="debeMostrarColumna('frecuencia.nombre')">
                                {{ item.frecuencia.nombre }}
                            </td> -->
                            <td v-if="debeMostrarColumna('frecuencia_sugerida.nombre')">
                                {{ item.frecuencia_sugerida ? item.frecuencia_sugerida.nombre : '-' }}
                            </td>
                            <td v-if="debeMostrarColumna('vencimiento')">
                                {{ item.vencimiento }}
                            </td>
                            <td v-if="debeMostrarColumna('updatedby.name')">
                                {{ item.updatedby.name }}
                            </td>
                            <td v-if="debeMostrarColumna('estado.nombre')">
                                {{ item.estado.nombre }}
                            </td>
                            <td v-if="debeMostrarColumna('obs_ult_visita')">
                                {{ item.obs_ult_visita }}
                            </td>
                            <td v-if="debeMostrarColumna('observacion')">
                                <div 
                                    v-html="resaltarPalabra(item.observacion)"
                                    class="scrollable-div"
                                >
                                </div>
                            </td>
                            <td v-if="debeMostrarColumna('reportabilidad')">
                                <div 
                                    v-html="reportabilidadNombre(item.reportabilidad)"
                                    class="scrollable-div"
                                >
                                </div>
                            </td>
                        </tr>
                    </draggable>
                </template>
            </template>
            <template v-slot:item.semaforo="{ item }">
                <v-tooltip bottom v-if="(view == 'compromiso' || view == 'ficha-compromiso')">
                    <template v-slot:activator="{ on, attrs }">
                        <div v-html="getSemaforo(item)"></div>
                    </template>
                </v-tooltip>
            </template>
            <template v-slot:item.rca="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <template v-for="rca in item.rca">
                            <v-chip
                                pill
                                class="ml-1 mr-1 mb-1 mt-1"
                                color="#477f8b"
                                text-color="white"
                            >
                            {{ rca.numero }}
                            </v-chip>
                        </template>
                    </template>
                </v-tooltip>
            </template>
            <template v-slot:item.categoria="{ item }">
                <v-tooltip bottom v-if="(view == 'estado')">
                    <template v-slot:activator="{ on, attrs }">
                        {{ getCategoriaEstado(item) }}
                    </template>
                </v-tooltip>
            </template>
            <template v-slot:item.url_doc="{ item }">
                <v-tooltip bottom v-if="(view == 'rca')">
                    <template v-slot:activator="{ on, attrs }">
                        <a :href="'https://seia.sea.gob.cl/expediente/ficha/fichaPrincipal.php?id_expediente='+item.id_expediente" target="_blank">Ir a ficha</a>
                    </template>
                </v-tooltip>
            </template>
            <template v-slot:item.nombre="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }" v-if="view == 'compromiso-documento' && item.link || view == 'ficha-instrumento' && item.link">
                        <a :href="item.link" target="_blank">{{ item.nombre }}</a>
                    </template>
                    <template v-slot:activator="{ on, attrs }" v-else>
                        {{ nombreRca(item.nombre) }}
                    </template>
                </v-tooltip>
            </template>
            <template v-slot:item.verificador="{ item }">
                <template v-for="(verificador, index) in item.verificador">
                    {{ verificador.nombre }}
                </template>
            </template>
            <template v-slot:item.componente="{ item }">
                <template v-for="(componente, index) in item.componente">
                    {{ componente.nombre }}
                </template>
            </template>
            <template v-slot:item.documento="{ item }" v-if="view == 'busquedaAvanzada'">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <a :href="`${urlDocumentos}/${item.documento}`" target="_blank">{{ item.documento.split('_')[1] }}</a>
                    </template>
                </v-tooltip>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-tooltip bottom v-if="!disabled && ((view == 'compromiso' || view == 'ficha-compromiso') ? item.editable == true : ((view != 'ejecuta-visita' && view != 'ficha-evento' && view != 'ficha-visita' && view != 'ficha-instrumento' && view != 'ficha-bitacora' && view != 'ficha-informe' && view != 'visita' && view != 'ficha-historico' && view != 'compromiso-documento' && view != 'visita-informe' && view != 'asignacion' && view != 'asignacion-titular' && view != 'ficha-documentos') || (view == 'visita' && item.estado.id != 17)))">
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            small
                            class="mr-6"
                            v-bind="attrs"
                            v-on="on"
                            @click="editViewItem(item, false)"
                        >
                            mdi-pencil
                        </v-icon>
                    </template>
                    <span>Editar</span>
                </v-tooltip>
                <v-tooltip bottom v-if="((view != 'ficha-instrumento' && view != 'ejecuta-visita' && view != 'ficha-historico' && view != 'asignacion' && view != 'asignacion-titular') || ( view == 'ficha-historico' && item.visita.nombre || view == 'ficha-historico' && item.documento)) && !(view == 'compromiso-documento' && item.link) && !(view == 'ficha-historico' && item.documento.link)">
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            small
                            class="mr-6"
                            v-bind="attrs"
                            v-on="on"
                            @click="editViewItem(item, true)"
                        >
                            mdi-eye
                        </v-icon>
                    </template>
                    <span>Ver</span>
                </v-tooltip>
                <v-tooltip bottom v-if="view == 'visita' && item.estado.id == 17 && (perfilStore.decryptData(perfil_id) == perfilStore.administrador || perfilStore.decryptData(perfil_id) == perfilStore.jefatura || perfilStore.decryptData(user_id) == item.responsable) && fechaValida(item)">
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            small
                            class="mr-6"
                            v-bind="attrs"
                            v-on="on"
                            @click="abrirVisita(item)"
                        >
                            mdi-format-horizontal-align-left
                        </v-icon>
                    </template>
                    <span>Abrir Visita</span>
                </v-tooltip>
                <v-tooltip bottom v-if="!disabled && (view == 'compromiso' && item.editable || view == 'compromiso-visita' || view == 'compromiso-documento' && item.createdby == perfilStore.decryptData(usuarioActual) || view == 'asignacion' || view == 'asignacion-titular' || view == 'ficha-documentos' && puedeEliminarCompromiso || view == 'ficha-instrumento' && puedeEliminarCompromiso || view == 'compromiso-documento' && puedeEliminarCompromiso)">
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            small
                            class="mr-6"
                            v-bind="attrs"
                            v-on="on"
                            @click="deleteItem(item, true)"
                        >
                            mdi-delete
                        </v-icon>
                    </template>
                    <span>Eliminar</span>
                </v-tooltip>
                <v-tooltip bottom v-if="perfilStore.decryptData(perfil_id) != perfilStore.titular && perfilStore.decryptData(perfil_id) != perfilStore.encargadoTitular && (view == 'proyecto' || view == 'titular')">
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            small
                            class="mr-6"
                            v-bind="attrs"
                            v-on="on"
                            @click="addRegistro(item)"
                        >
                            mdi-plus
                        </v-icon>
                    </template>
                    <span>Agregar {{ btnMas }}</span>
                </v-tooltip>
                <v-tooltip bottom v-if="view == 'proyecto'">
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            small
                            class="mr-6"
                            v-bind="attrs"
                            v-on="on"
                            @click="viewFicha(item)"
                        >
                            mdi-file-document
                        </v-icon>
                    </template>
                    <span>Ficha de Unidad Fiscalizable</span>
                </v-tooltip>
                <v-tooltip bottom v-if="view == 'visita' && item.estado.id == 15">
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            small
                            class="mr-6"
                            v-bind="attrs"
                            v-on="on"
                            @click="ejecutarVisita(item)"
                        >
                            mdi-play
                        </v-icon>
                    </template>
                    <span>Acta de terreno</span>
                </v-tooltip>
                <v-tooltip bottom v-if="(view == 'ficha-instrumento' && item.ruta || view == 'ficha-informe' || view == 'visita-informe' || view == 'compromiso-documento' && item.ruta || view == 'ficha-documentos' || view == 'ficha-historico' && item.documento) && !(view == 'ficha-historico' && item.documento.link)">
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            small
                            class="mr-6"
                            v-bind="attrs"
                            v-on="on"
                            @click="descargar(item.documento || item)"
                        >
                            mdi-cloud-download
                        </v-icon>
                    </template>
                    <span>Descargar</span>
                </v-tooltip>
                <v-tooltip bottom v-if="(view == 'ejecuta-visita')">
                    <template v-slot:activator="{ on, attrs }">
                        {{ item.cant_adj > 0 ? "(" + item.cant_adj + ")" : "" }}
                        <v-icon                            
                            class="mr-6"
                            v-bind="attrs"
                            v-on="on"
                            @click="cargarDocumentos(item)"
                        >
                            mdi-paperclip
                        </v-icon>
                    </template>
                    <span>Cargar documento</span>
                </v-tooltip>
            </template>
            <template v-slot:item.activo="{ item }">
                <v-chip
                    class="ma-2"
                    :color=getColorChipActive(item.activo)
                    text-color="white"
                    small
                >
                    {{getNameChipActive(item.activo)}}
                </v-chip>
            </template>
            <template v-slot:item.auditoria="{ item }">
                {{ getTipoActividadVisita(item.auditoria) }}
            </template>
            <template v-slot:item.descripcion="{ item }">
                <div 
                    v-html="resaltarPalabra(item.descripcion)"
                    class="scrollable-div"
                ></div>
            </template>
            <template v-slot:item.valorAnterior="{ item }">
                <div 
                    v-html="resaltarPalabra(item.valorAnterior)"
                    class="scrollable-div"
                ></div>
            </template>
            <template v-slot:item.reportabilidad="{ item }">
                <div 
                    v-html="reportabilidadNombre(item.reportabilidad)"
                ></div>
            </template>
            <template v-slot:item.valorANuevo="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }" v-if="view == 'ficha-historico' && item.documento && item.documento.link">
                        <a :href="item.documento.link" target="_blank">{{ item.documento.nombre }}</a>
                    </template>
                    <template v-slot:activator="{ on, attrs }" v-else>
                        <div 
                            v-html="resaltarPalabra(item.valorANuevo)"
                            class="scrollable-div"
                        ></div>
                    </template>
                </v-tooltip>
            </template>
            <template v-slot:item.estado-editable="{ item }">
                <select-component label="" :ref="'cmbEstados' + item.compromiso_id" :data="estados" :valor="setearValorSelect(item)" @selected-value="valorSelectEstadoWrapper(item)"></select-component>
            </template>
            <template v-slot:item.observacion-editable="{ item }">
                <text-area-component label="" :rows=2 :ref="'taObservacion' + item.compromiso_id" :valor="setearValorTextArea(item, 'taObservacion')" @value="valorTextArea(item, 'taObservacion')" :counterValue="5000"></text-area-component>
            </template>
            <template v-slot:item.plan_accion-editable="{ item }">
                <text-area-component label="" :rows=2 :ref="'taPlanAccion' + item.compromiso_id" :valor="setearValorTextArea(item, 'taPlanAccion')" @value="valorTextArea(item, 'taPlanAccion')" :counterValue="5000"></text-area-component>
            </template>
            <template v-slot:item.compromiso.nombre="{ item }">
                <v-tooltip bottom v-if="(view == 'ficha-documentos')">
                    <template v-slot:activator="{ on, attrs }">
                        <a @click="openCompromiso(item.compromiso)" class="link">{{item.compromiso ? item.compromiso.nombre : ''}}</a>
                    </template>
                </v-tooltip>
            </template>
            <template v-slot:item.ufs.length="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }" v-if="view == 'informacion-titulares'">
                        <a :href="item.link" target="_blank" @click="clickTitulares(item)">{{ item.ufs.length }}</a>
                    </template>
                    <template v-slot:activator="{ on, attrs }" v-else>
                        {{ nombreRca(item.nombre) }}
                    </template>
                </v-tooltip>
            </template>
            <template v-slot:item.uf.nombre="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }" v-if="view == 'uf-titulares'">
                        <a :href="item.link" target="_blank" @click="viewFicha(item.uf)">{{ item.uf.nombre }}</a>
                    </template>
                    <template v-slot:activator="{ on, attrs }" v-else>
                        {{ nombreRca(item.nombre) }}
                    </template>
                </v-tooltip>
            </template>
        </v-data-table>
        <br>
        <v-row>
            <v-col cols="11"></v-col>
            <v-spacer></v-spacer>
            <v-col v-if="(!disabled && showAdd)">
                <v-btn
                    class="mx-2"
                    fab
                    dark
                    small
                    color="green"
                    @click="newItem"
                >
                    <v-icon dark>
                        mdi-plus
                    </v-icon>
                </v-btn>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import axios from "axios";
    import TitularForm from "@/views/formulario/TitularForm.vue";
    import ProyectoForm from "@/views/formulario/ProyectoForm.vue"
    import CompromisoForm from "@/views/formulario/CompromisoForm.vue";
    import ContactoForm from "@/views/formulario/ContactoForm.vue";
    import VisitaForm from "@/views/formulario/VisitaForm.vue";
    import FormularioMantenedorComponent from "./mantenedor/FormularioMantenedorComponent.vue";
    import EjecutarVisita from "@/views/EjecutarVisita.vue";
    import BitacoraForm from "@/views/formulario/BitacoraForm.vue";
    import * as ExcelJS from 'exceljs';
    import { EventBus } from "@/event/event"
    import DialogYesNoComponent from "./DialogYesNoComponent.vue";
    import { mantenedorStore } from "@/store/MantenedorStore";
    import { perfilStore } from "@/store/PerfilStore"
    import NuevoEvento from "./calendario/NuevoEvento.vue";
    import DocumentosForm from "@/views/formulario/DocumentosForm.vue";
    import draggable from 'vuedraggable'
    import { commonStore } from "@/store/CommonStore";
    import DialogViewDocument from "./DialogViewDocument.vue";
    import DialogComponent from "./DialogComponent.vue"
    import UsuarioTitularForm from "@/components/mantenedor/UsuarioTitularForm.vue";
    export default {
        components: {
            TitularForm,
            ProyectoForm,
            CompromisoForm,
            ContactoForm,
            VisitaForm,
            FormularioMantenedorComponent,
            EjecutarVisita,
            BitacoraForm,
            DialogYesNoComponent,
            NuevoEvento,
            DocumentosForm,
            draggable,
            DialogViewDocument,
            DialogComponent,
            UsuarioTitularForm
        },
        props: {
            headers: {
                type: Array
            },
            url: {
                type: String
            },
            view: {
                type: String
            },
            disabled: {
                type: Boolean,
                default: false
            },
            labelSearch: {
                type: String
            },
            showSelect: {
                type: Boolean,
                default: false
            },
            itemKey: {
                type: String,
                default: 'id'
            },
            valor: {
                type: Array
            },
            isSearch: {
                type: Boolean,
                default: true
            },
            tableHeight: {
                type: Number
            },
            rowsPerPage: {
                type: Number,
                default: 5
            },
            exportExcel: {
                type: Boolean,
                default: true
            },
            showAdd: {
                type: Boolean,
                default: true
            },
            padreId: {
                type: Number
            },
            data: {
                type: Array
            },
            disbleItemsPerPage: {
                type: Boolean,
                default: false
            },
            disablePagination: {
                type: Boolean,
                default: false
            },
            faseProp: {
                type: [Object, String, Array],
                default: null
            },
            isSeleccionColumnas: {
                type: Boolean,
                default: true
            },
            filtroEstado: {
                type: String,
                default: null
            },
            puedeEliminarCompromiso: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                items: [],
                currentPage: 1,
                searchInput: "",
                loading: false,
                btnMas: null,
                selected: [],
                page: 1,
                paginas: 1,
                elementosPorPagina: 1,
                total: 1,
                enlace: '',
                titular: null,
                proyecto: null,
                keyTable: 0,
                mostrarFiltros: false,
                selectedHeaders: [],
                panel: [0, 1],
                menuOpen: false,
                ocultarElementosPorPagina: false,
                rowsPerPage_: null,
                snackbar: false,
                texto: '',
                estado: 0,
                mantenedorStore: mantenedorStore(),
                mostrarfiltroColumna: false,
                filtroColumnas: {},
                perfilStore: perfilStore(),
                commonStore: commonStore(),
                scroll: null,
                urlDocumentos: process.env.VUE_APP_URL_DOCUMENTOS,
                usuarioActual: localStorage.getItem('id'),
                estadoData: [],
                estadoObservacion: [],
                estadoPlanAccion: [],
                estados: [],
                keyDraggable: 0,
                perfil_id: null,
                user_id: null,
                mostrarFiltrosCompromisos: false,
                estadoCategoria: null,
                frecuenciaSugerida: null,
                frecuencia: null,
                fase: null,
                componente: null,
                verificador: null,
                registro: ['completo', 'incompleto'],
                registroSeleccionado: null,
                itemsAux: [],
                totalCompromisos: 0,
                seleccion: false,
                selectedDragItems: [],
                showDeleteButton: false,
                allSelected: false,
                isIndeterminate: false,
            }
        },
        created() {
            this.perfil_id = localStorage.getItem('perfil_id')
            this.user_id = localStorage.getItem('id')
            switch (this.view) {
                case 'compromiso':
                    if (this.perfilStore.decryptData(this.perfil_id) == this.perfilStore.titular && this.perfilStore.decryptData(this.perfil_id) == this.perfilStore.encargadoTitular) {
                        this.selectedHeaders = [
                            "",
                            "actions",
                            'codigo',
                            'nombre',
                            'descripcion',
                            'origen',
                            'verificador',
                            'descripcion_verificador',
                            'componente',
                            'fase.nombre',
                            'frecuencia_sugerida.nombre',
                            'reportabilidad',
                            'estado.nombre',
                            'obs_ult_visita'
                        ]
                    } else {
                        this.selectedHeaders = [
                            "",
                            "semaforo",
                            "actions",
                            'codigo',
                            'nombre',
                            'descripcion',
                            'origen',
                            'verificador',
                            'descripcion_verificador',
                            'componente',
                            'fase.nombre',
                            'frecuencia_sugerida.nombre',
                            'reportabilidad',
                            'estado.nombre',
                            'obs_ult_visita'
                        ]
                    }
                    break;
                case 'ficha-compromiso':
                if (this.perfilStore.decryptData(this.perfil_id) == this.perfilStore.titular && this.perfilStore.decryptData(this.perfil_id) == this.perfilStore.encargadoTitular) {
                        this.selectedHeaders = [
                            "",
                            "actions",
                            "codigo",
                            "nombre",
                            "descripcion",
                            "origen",
                            'updatedby.name',
                            'estado.nombre',
                            'obs_ult_visita',
                            'reportabilidad'
                        ]
                    } else {
                        this.selectedHeaders = [
                            "",
                            "semaforo",
                            "actions",
                            "codigo",
                            "nombre",
                            "descripcion",
                            "origen",
                            'updatedby.name',
                            'estado.nombre',
                            'obs_ult_visita',
                            'reportabilidad'
                        ]
                    }
                    break
                case 'compromiso-visita':
                    if (this.perfilStore.decryptData(this.perfil_id) == this.perfilStore.titular && this.perfilStore.decryptData(this.perfil_id) == this.perfilStore.encargadoTitular) {
                        this.selectedHeaders = [
                            "",
                            "actions",
                            "codigo",
                            "nombre",
                            "descripcion",
                            "origen",
                            'updatedby.name',
                            'estado.nombre',
                            'reportabilidad'
                        ]
                    } else {
                        this.selectedHeaders = [
                            "",
                            "semaforo",
                            "actions",
                            "codigo",
                            "nombre",
                            "descripcion",
                            "origen",
                            'updatedby.name',
                            'estado.nombre',
                            'reportabilidad'
                        ]
                    }
                    break;
                case 'ejecuta-visita':
                    this.selectedHeaders = [
                        "codigo",
                        "nombre",
                        "descripcion",
                        "componente",
                        "verificador",
                        "descripcion_verificador",
                        'estado-editable',
                        'observacion-editable',
                        'plan_accion-editable',
                        'actions'
                    ]
                    break;
                default:
                    this.selectedHeaders = this.headers.map(header => header.value);
                    break;
            }
            this.rowsPerPage_ = this.rowsPerPage
            this.seleccion = this.showSelect
        },
        mounted () {
            this.getEstados()
            if (this.faseProp && (this.perfilStore.decryptData(this.perfil_id) != this.perfilStore.analista)) {
                this.mostrarFiltrosCompromisos = true
                this.$nextTick(() => {
                    this.$refs.sFase.setValue(this.faseProp)
                    this.filtrosCompromisos()
                })
            } else {
                this.getItems()
            }
            switch (this.view) {
                case 'proyecto':
                    this.btnMas = 'compromiso'
                    break;
                case 'titular':
                    this.btnMas = 'contacto'
                    break;
                case 'usuario-titular':
                    this.suscribirse()
                    break
            }
            this.selected = this.valor
            EventBus.$on('refresh-documentos', (response) => {
                if (response) {
                    this.snackbar = true;
                    this.texto = response.data.message
                    this.estado = response.data.code
                }
            });
        },
        computed: {
            model: {
                get: function() {
                    return this.selected
                },
                set: function(newValue) {
                    this.selected = newValue;
                },
                paginationLength() {
                    return this.paginas;
                },
            },
        },
        methods: {
            suscribirse() {
                EventBus.$on('refresh-table', (response) => {
                    this.getItems()
                    if (response) {
                        this.snackbar = true;
                        this.texto = response.data.message
                        this.estado = response.data.code
                    }
                });
            },
            async getItems(filtro) {
                if (this.data) {
                    this.items = this.data
                } else {
                    if (this.url) {
                        this.loading = true;
                        if (this.url.includes('compromisos') || this.url.includes('agenda')) {
                            this.rowsPerPage_ = -1
                        }
                        if (this.mostrarFiltrosCompromisos) {
                            await this.filtrosCompromisos()
                        } else {
                            let params = {
                                rowsPerPage: this.rowsPerPage_,
                                user_id: this.perfilStore.decryptData(localStorage.getItem('id')),
                                view: this.view
                            };
                            await axios.get(this.url, { params })
                            .then(response => {
                                if (response.data.body.items && response.data.body.items.length > 0) {
                                    this.items = response.data.body.items;
                                    if (this.url.includes('compromisos/proyecto')) {
                                        this.totalCompromisos = response.data.body.total_compromisos
                                    }
                                }
                                this.loading = false;
                                if (filtro != 'columna') {
                                    this.keyTable++
                                }
                                this.$emit('tabla-cargada')
                            });
                        }
                        if (this.view == 'compromiso') {
                            this.$nextTick(() => {
                                if (this.$refs.tablaGeneralRef) {
                                    this.$refs.tablaGeneralRef.$el.querySelector('.v-data-table__wrapper').scrollTop = this.scroll
                                }
                            })
                        }
                    }
                }
            },
            async editViewItem(item, readOnly) {
                // View debe ser singular
                switch (this.view) {
                    case 'titular':
                        this.$refs.titularRef.open(item, readOnly)
                        break;
                    case 'proyecto':
                        this.$refs.proyectoRef.open(item, readOnly)
                        break;
                    case 'compromiso':
                        this.scroll = this.$refs.tablaGeneralRef.$el.querySelector('.v-data-table__wrapper').scrollTop
                        this.$refs.compromisoRef.open(item, readOnly, '', this.totalCompromisos)
                        break;
                    case 'contacto':
                        this.$refs.contactoRef.open(item, readOnly)
                        break;
                    case 'visita':
                        this.$refs.visitaRef.open(item, readOnly)
                        break;
                    case 'componente':
                        this.$refs.formMantenedorRef.open(item, readOnly, this.url, this.view)
                        break;
                    case 'fase':
                        this.$refs.formMantenedorRef.open(item, readOnly, this.url, this.view)
                        break;
                    case 'verificador':
                        this.$refs.formMantenedorRef.open(item, readOnly, this.url, this.view)
                        break;
                    case 'frecuencia':
                        this.$refs.formMantenedorRef.open(item, readOnly, this.url, this.view)
                        break;
                    case 'usuario':
                        this.$refs.formMantenedorRef.open(item, readOnly, this.url, this.view)
                        break;
                    case 'instrumento':
                        this.$refs.formMantenedorRef.open(item, readOnly, this.url, this.view)
                        break;
                    case 'ficha-compromiso':
                        this.$refs.compromisoRef.open(item, readOnly, 'ficha-compromiso', this.totalCompromisos)
                        break;
                    case 'ficha-visita':
                        this.$refs.visitaRef.open(item, readOnly)
                        break;
                    case 'ficha-evento':
                        if (item.visita_id) {
                            item.id = item.visita_id
                            this.$refs.visitaRef.open(item, readOnly)
                        } else {
                            this.$refs.eventoRef.open(item, readOnly)
                        }
                        break;
                    case 'ficha-bitacora':
                        this.$refs.bitacoraRef.open(item, readOnly, item.proyecto.id)
                        break;
                    case 'region':
                        this.$refs.formMantenedorRef.open(item, readOnly, this.url, this.view)
                        break;
                    case 'comuna':
                        this.$refs.formMantenedorRef.open(item, readOnly, this.url, this.view)
                        break;
                    case 'estado':
                        this.$refs.formMantenedorRef.open(item, readOnly, this.url, this.view)
                        break;
                    case 'visita-informe':
                        if (item.flag.id == this.commonStore.flagPlanVisita) {
                            this.mostrarReporte(item, 'plan')
                        } else if (item.flag.id == this.commonStore.flagActaTerreno) {
                            this.mostrarReporte(item, 'revision_final')
                        }
                        break;
                    case 'ficha-informe':
                        if (item.flag.id == this.commonStore.flagPlanVisita) {
                            this.mostrarReporte(item, 'plan')
                        } else if (item.flag.id == this.commonStore.flagActaTerreno) {
                            this.mostrarReporte(item, 'revision_final')
                        }
                        break;
                    case 'codigo':
                        this.$refs.formMantenedorRef.open(item, readOnly, this.url, this.view)
                        break;
                    case 'ficha-historico':
                        if (item.documento) {
                            this.$refs.viewDocumentRef.open(item.documento)
                            break
                        }
                        this.$refs.visitaRef.open(item.visita, readOnly)
                        break;
                    case 'compromiso-visita':
                        this.scroll = this.$refs.tablaGeneralRef.$el.querySelector('.v-data-table__wrapper').scrollTop
                        this.$refs.compromisoRef.open(item, readOnly, '', this.totalCompromisos)
                        break;
                    case 'ficha-documentos':
                        this.$refs.viewDocumentRef.open(item)
                        break
                    case 'compromiso-documento':
                        this.$refs.viewDocumentRef.open(item)
                        break
                    case 'usuario-titular':
                        this.$refs.usuarioTitularForm.open(item, readOnly)
                        break
                }
            },
            addRegistro(item) {
                switch (this.view) {
                    case 'proyecto':
                        this.$refs.compromisoRef.open(item, false, '', this.totalCompromisos)
                        break;
                    case 'titular':
                        this.$refs.contactoRef.open(item, false)
                        break;
                }
            },
            handleSearchName(e) {
                if (this.view == 'ejecuta-visita') {
                    this.keyTable++
                }
                let timer;
                const waitTime = 500;
                clearTimeout(timer);
                timer = setTimeout(() => {
                    this.searchByNameFetch(e.target.value);
                }, waitTime);
            },
            async searchByNameFetch(name) {
                this.$emit("search-name", name);
            },
            getColorChipActive(item) {
                if (item) {
                    return "green"
                } else {
                    return "red"
                }
            },
            getNameChipActive(item) {
                if (item) {
                    return "Activo"
                } else {
                    return "Inactivo"
                }
            },
            getValue() {
                return this.selected
            },
            setValue(value) {
                this.modelo = value;
            },
            async filtros() {
                let url = '';
                switch (this.view) {
                    case 'proyecto':
                        url = '/proyectos/filtrar';
                        break;
                    case 'visita':
                        url = '/visitas/filtrar';
                        break;
                }
                let titularValue = []
                let proyectoValue = []

                if(this.view == 'proyecto') {
                    titularValue = this.$refs.sTitular.getValue()
                }
                if(this.view == 'visita') {
                    proyectoValue = this.$refs.sProyecto.getValue()
                    titularValue = this.$refs.sTitular.getValue()
                }
                const params = {
                    titular: titularValue.id ? titularValue.id : null,
                    proyecto: proyectoValue.id ? proyectoValue.id : null,
                };
                await axios.get(url, { params })
                .then(response => {
                    /* this.total = response.data.body.meta.total
                    this.elementosPorPagina = response.data.body.meta.per_page
                    this.currentPage = response.data.body.meta.current_page
                    if(this.total / this.elementosPorPagina > 1) {
                        this.paginas = Math.ceil(this.total / this.elementosPorPagina)
                    } else {
                        this.paginas = 1
                    }
                    this.enlace = response.data.body.links.first */
                    this.items = response.data.body.items;
                    this.loading = false;
                    this.keyTable++
                })
                .catch(error => {
                    console.error(error);
                });
            },
            async filtrosCompromisos() {
                this.loading = true
                const params = {
                    verificador_id: (this.$refs.sVerificador != undefined && this.$refs.sVerificador.getValue().id) ? this.$refs.sVerificador.getValue().id : null,
                    componente_id: (this.$refs.sComponente != undefined && this.$refs.sComponente.getValue().id) ? this.$refs.sComponente.getValue().id : null,
                    fase_id: (this.$refs.sFase != undefined && this.$refs.sFase.getValue().id) ? this.$refs.sFase.getValue().id : null,
                    frecuencia_id: (this.$refs.sFrecuencia != undefined && this.$refs.sFrecuencia.getValue().id) ? this.$refs.sFrecuencia.getValue().id : null,
                    frecuencia_sugerida_id: (this.$refs.sFrecuenciaSugerida != undefined && this.$refs.sFrecuenciaSugerida.getValue().id) ? this.$refs.sFrecuenciaSugerida.getValue().id : null,
                    //estado_id: (this.$refs.sEstadoCompromiso != undefined && this.$refs.sEstadoCompromiso.getValue().id) ? this.$refs.sEstadoCompromiso.getValue().id : null,
                    estado_id: this.$refs.sEstadoCompromiso.getValue().id,
                    proyecto_id: this.padreId
                }
                await axios.get('/filtro/compromisos', {params}).then(response => {
                    this.items = response.data.body.items;
                    this.loading = false;
                    this.keyTable++
                    this.totalCompromisos = response.data.body.total_compromisos
                }).catch(error => {
                    console.error(error)
                })
            },
            toggleFilter() {
                this.mostrarFiltros = !this.mostrarFiltros;
            },
            toggleFilterCompromisos() {
                this.mostrarFiltrosCompromisos = !this.mostrarFiltrosCompromisos;
            },
            viewFicha(item) {
                this.$router.push({name: 'ficha', query: {proyecto: item.id}}) 
            },
            ejecutarVisita(item) {
                this.keyTable++
                this.$refs.ejecutarVisitaRef.open(item)
            },
            getItemPerPage(cant) {
                this.rowsPerPage_ = cant
                this.page = 1
                this.getItems()
            },
            async descargar(item) {
                let fileName = item.ruta.split(/[\\/]/).pop();
                if (fileName.includes('___')) {
                    fileName = fileName.split('___')[1]
                }
                axios({
                    url: "/documento/download/" + item.id,
                    method: 'GET',
                    responseType: 'blob',
                }).then(response => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', fileName);
                    document.body.appendChild(link);
                    link.click();
                    window.URL.revokeObjectURL(url);
                }).catch(error => {
                    this.snackbar = true;
                    this.texto = 'Error al descargar documento'
                    this.estado = 500
                });
            },
            getTipoActividadVisita(tipo) {
                if (tipo) {
                    return 'Auditoría';
                } else {
                    return 'Inspección';
                }
            },
            resaltarPalabra(text) {
                if(text){
                    const palabraEscapada = this.searchInput.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
                    const regex = new RegExp(`(${palabraEscapada})`, 'gi');
                    //const limitedText = text.length > 100 ? text.slice(0, 100) + '...' : text;
                    const highlightedText = text.replace(regex, '<span style="font-weight: bold; background-color: yellow;">$1</span>');
                    return `<div>${highlightedText}</div>`;
                }
            },
            closeMenu() {
                this.menuOpen = false;
            },
            obtenerValoresEjecucion() {
                let arreglo = []
                this.items.forEach(item => {
                    const refEstado = `cmbEstados${item.compromiso_id}`;
                    const refObservacion = `taObservacion${item.compromiso_id}`;
                    const refPlanAccion = `taPlanAccion${item.compromiso_id}`;
                    const selectEstado = this.$refs[refEstado];
                    const textObservacion = this.$refs[refObservacion];
                    const textPlanAccion = this.$refs[refPlanAccion];

                    const valorEstado = selectEstado ? selectEstado.getValue() != null ? selectEstado.getValue() : '' : ''
                    const valorObservacion = textObservacion ? textObservacion.getValue() != null ? textObservacion.getValue() : '' : ''
                    const valorPlanAccion = textPlanAccion ? textPlanAccion.getValue() != null ? textPlanAccion.getValue() : '' : ''
                    if (valorEstado != '' || valorObservacion != '' || valorPlanAccion != '') {
                        arreglo.push({'compromiso_id': item.compromiso_id, 'estado': valorEstado, 'observacion': valorObservacion, 'plan': valorPlanAccion})
                    }
                });
                return arreglo
            },
            async setearValoresEjecucion(items) {
                await this.$nextTick(); 
                let arreglo = items.compromiso_visita
                for (const item of arreglo) {
                    await this.$nextTick();
                    const refEstado = `cmbEstados${item.compromiso_id}`;
                    const refObservacion = `taObservacion${item.compromiso_id}`;
                    const refPlanAccion = `taPlanAccion${item.compromiso_id}`;
                    const selectEstado = this.$refs[refEstado];
                    const textObservacion = this.$refs[refObservacion];
                    const textPlanAccion = this.$refs[refPlanAccion];
                    if (selectEstado) {
                        selectEstado.setValue(item.estado)
                        this.estadoData[item.compromiso_id] = {valor: item.estado, id: item.compromiso_id}
                    }
                    if (textObservacion) {
                        textObservacion.setValue(item.observacion)
                        this.estadoObservacion[item.compromiso_id] = {valor: item.observacion, id: item.compromiso_id}
                    }
                    if (textPlanAccion) {
                        textPlanAccion.setValue(item.plan_accion)
                        this.estadoPlanAccion[item.compromiso_id] = {valor: item.plan_accion, id: item.compromiso_id}
                    }
                }
                
            },
            async exportToExcel() {
                //this.$refs.load.open();
                let data = this.items;
                const workbook = new ExcelJS.Workbook();
                let worksheet;
                let nombreArchivo = this.view

                if(this.items.length > 0) {
                    let headerMap;
                    if (this.view == 'compromiso' || this.view == 'ficha-compromiso') {
                        worksheet = workbook.addWorksheet('Compromisos');
                        let base64, nombre, titular, rca
                        const paramsExcel = {
                            proyecto_id: data[0].proyecto_id
                        }
                        await axios({
                            url: '/datos-excel',
                            method: 'GET',
                            params: paramsExcel
                        }).then( response => {
                            base64 = response.data.body.base64
                            nombre = response.data.body.nombre
                            titular = response.data.body.titular
                            rca = response.data.body.rca
                        })
                        const imageId = workbook.addImage({
                            base64: base64,
                            extension: 'png',
                        });
                        worksheet.addImage(imageId, {
                            tl: { col: 0, row: 1 },
                            ext: { width: 530, height: 90 }
                        });
                        worksheet.getColumn('A').width = 10;
                        worksheet.getColumn('B').width = 25;
                        worksheet.getColumn('C').width = 60;
                        worksheet.getColumn('D').width = 15;
                        worksheet.getColumn('E').width = 15;
                        worksheet.getColumn('F').width = 28;
                        worksheet.getColumn('G').width = 20;
                        worksheet.getColumn('H').width = 20;
                        worksheet.getColumn('I').width = 60;
                        worksheet.getColumn('J').width = 20;
                        worksheet.getColumn('K').width = 20;
                        worksheet.getColumn('L').width = 20;
                        worksheet.getColumn('M').width = 20;
                        worksheet.getColumn('N').width = 60;
                        worksheet.getColumn('O').width = 20;
                        worksheet.getColumn('P').width = 60;
                        worksheet.getCell('D2').value = 'RCA N° ' + rca;
                        worksheet.getCell('D3').value = 'Titular: ' + titular;
                        worksheet.getCell('D4').value = 'Nombre del documento: “Matriz de Cumplimiento Ambiental del ' + nombre +'"';
                        for (let i = 0; i < 2; i++) {
                            worksheet.addRow([]);
                        }
                        nombreArchivo = 'Compromisos ' + nombre

                        headerMap = {
                            codigo: 'Código',
                            nombre: 'Nombre',
                            descripcion: 'Descripción',
                            origen: 'Origen',
                            norma: 'Norma',
                            componente: 'Componente',
                            fase: 'Fase',
                            verificador: 'Verificador',
                            descripcion_verificador: 'Descripción del verificador',
                            frecuencia_sugerida: 'Frecuencia',
                            vencimiento: 'Vencimiento',
                            autoridad: 'Autoridad',
                            responsable: 'Responsable',
                            observacion: 'Observación',
                            reportabilidad: 'Reportabilidad'
                        }
                    } else {
                        worksheet = workbook.addWorksheet('Sheet1');

                        headerMap = {
                            region: 'Region',
                            titular: 'Titular',
                            comuna: 'Comuna',
                            proyecto: 'Proyecto',
                            componente: 'Componente',
                            fase: 'Fase',
                            verificador: 'Verificador',
                            /* frecuencia: 'Frecuencia', */
                            frecuencia_sugerida: 'Frecuencia',
                            contactos: 'Contactos',
                            estado: 'Estado',
                            compromisos: 'Compromisos',
                            rca: 'RCA',
                            codigo_dss: 'Código DSS',
                            tipo_coordenada: 'Tipo de Coordenada',
                            instrumento: 'Instrumento',
                            descripcion_verificador: 'Descripción del verificador',
                            codigo: 'Código',
                            nombre: 'Nombre',
                            descripcion: 'Descripción',
                            origen: 'Origen',
                            norma: 'Norma',
                            vencimiento: 'Vencimiento',
                            autoridad: 'Autoridad',
                            responsable: 'Responsable',
                            observacion: 'Observación'
                        }
                    }

                    const excludedColumns = [
                        'createdby',
                        'updatedby',
                        'compromiso_visita',
                        'documentos',
                        'compromiso_id',
                        'proyecto_id',
                        'plan_accion',
                        'instrumento',
                        'frecuencia',
                        'cant_adj',
                        'confirmacion_faltante'
                    ];

                    //const headers = Object.keys(data[0]).filter(header => !excludedColumns.includes(header)).map(header => headerMap[header] || header);
                    const headers = Object.keys(headerMap).filter(header => !excludedColumns.includes(header)).map(header => headerMap[header]);
                    const headerRow = worksheet.addRow(headers);

                    if (this.view == 'compromiso' || this.view == 'ficha-compromiso') {
                        headerRow.eachCell((cell) => {
                            cell.alignment = { horizontal: 'center', vertical: 'middle', wrapText: true };
                            cell.fill = {
                                type: 'pattern',
                                pattern: 'solid',
                                fgColor: { argb: '215968' } 
                            };
                            cell.font = {
                                bold: true,
                                color: { argb: 'FFFFFFFF' },
                                name: 'Calibri'
                            };
                            worksheet.getRow(cell.row).height = 30;
                        });
                    }

                    data.forEach((row) => {
                        const rowData = headers.map((header) => {
                            const originalHeader = Object.keys(headerMap).find(key => headerMap[key] === header) || header;
                            if (originalHeader === 'region') {
                                const region = row[originalHeader];
                                return region ? region.nombre : '';
                            } else if (originalHeader === 'titular') {
                                const titular = row[originalHeader];
                                return titular ? titular.nombre : '';
                            } else if (originalHeader === 'comuna') {
                                const comuna = row[originalHeader];
                                return comuna ? comuna.nombre : '';
                            } else if(originalHeader === 'proyecto') {
                                const proyecto = row[originalHeader];
                                return proyecto ? proyecto.nombre : '';
                            } else if(originalHeader === 'componente') {
                                const componente = row[originalHeader];
                                if (!componente || componente.length === 0) {
                                    return '';
                                }
                                let nombresComponentes = '';
                                componente.forEach(c => {
                                    nombresComponentes += c.nombre + ', ';
                                });
                                nombresComponentes = nombresComponentes.slice(0, -2);
                                return nombresComponentes;
                            } else if(originalHeader === 'fase') {
                                const fase = row[originalHeader];
                                return fase ? fase.nombre : '';
                            } else if(originalHeader === 'verificador') {
                                const verificador = row[originalHeader];
                                if (!verificador || verificador.length === 0) {
                                    return '';
                                }
                                let nombresVerificadores = '';
                                verificador.forEach(c => {
                                    nombresVerificadores += c.nombre + ', ';
                                });
                                nombresVerificadores = nombresVerificadores.slice(0, -2);
                                return nombresVerificadores;
                            } else if (originalHeader === 'frecuencia' || originalHeader === 'frecuencia_sugerida') {
                                const frecuencia = row[originalHeader];
                                return frecuencia ? frecuencia.nombre : '';
                            } else if (originalHeader === 'contactos') {
                                const contacto = row[originalHeader];
                                if (!contacto || contacto.length === 0) {
                                    return '';
                                }
                                let nombresContacto = '';
                                contacto.forEach(c => {
                                    nombresContacto += c.nombre + ', ';
                                });
                                nombresContacto = nombresContacto.slice(0, -2);
                                return nombresContacto;
                            } else if (originalHeader === 'estado') {
                                const estado = row[originalHeader];
                                return estado ? estado.nombre : '';
                            } else if (originalHeader === 'compromisos') {
                                const compromisos = row[originalHeader]
                                if (!compromisos || compromisos.length === 0) {
                                    return '';
                                }
                                let nombreCompromiso = ''
                                compromisos.forEach(compromiso => {
                                    nombreCompromiso += compromiso.nombre + ', ';
                                });
                                nombreCompromiso = nombreCompromiso.slice(0, -2);
                                return nombreCompromiso;
                            } else if (originalHeader === 'rca') {
                                if (row[originalHeader]) {
                                    const rca = row[originalHeader];
                                    var rcas = ''
                                    rca.forEach(element => {
                                        if (element.nombre != null) {
                                            rcas += element.nombre + ' | '
                                        }
                                    });
                                    return rcas.substring(0, rcas.length-3);
                                }
                                return ''
                            } else if (originalHeader === 'codigo_dss') {
                                const codigo_dss = row[originalHeader];
                                return codigo_dss ? codigo_dss.codigo_largo : '';
                            } else if (originalHeader === 'tipo_coordenada') {
                                if (row[originalHeader] != '[object Object]' && row[originalHeader] != null && row[originalHeader] != 'null') {
                                    return row[originalHeader]
                                }
                            } else if (originalHeader === 'instrumento') {
                                const rca = row[originalHeader];
                                return rca ? rca.nombre : '';
                            } else if(originalHeader === 'reportabilidad') {
                                /* const report = row[originalHeader];
                                return report ? report : ''; */
                                let report = row[originalHeader];
                                if(report) {
                                    let sinEspacios = report.replace(/\s+/g, '');
                                    let tamanio = sinEspacios.length
                                    if (tamanio == 1) {
                                        switch (sinEspacios) {
                                            case 'R':
                                                return 'Reportar a la autoridad'
                                            case 'M':
                                                return 'Mantener registro en obra'
                                        }
                                    } else {
                                        return 'Mantener registro en obra / \nReportar a la autoridad'
                                    }
                                }
                            } else {
                                return row[originalHeader];
                            }
                        });
                        const row_ = worksheet.addRow(rowData);
                        let maxLength = 20;
                        row_.eachCell((cell) => {
                            cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
                            let calcHeight = (cell.value.toString().trim().length/cell._column.width)*23;
                            if (calcHeight > maxLength) {
                                maxLength = calcHeight
                            }
                        });
                        worksheet.getRow(row_._number).height = maxLength;
                    });
                    const blob = await workbook.xlsx.writeBuffer();

                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(new Blob([blob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
                    link.download = nombreArchivo + '.xlsx';
                    link.click();
                    //this.$refs.load.close();
                }
            },
            async mostrarReporte(item, tipo) {
                //this.$refs.load.open();
                let nombre = item.nombre
                const params = {
                    documento_id: item.id,
                    nombre: nombre,
                    tipo: tipo,
                }
                await axios({
                    url: '/show-report',
                    method: 'GET',
                    params: params
                }).then(response => {
                    if (response.data.code != 404) {
                        if (nombre.includes('___')) {
                            nombre = nombre.split('___')[1]
                        }
                        EventBus.$emit('open-informe', 'data:application/pdf;base64,'+response.data.body.base64, response.data.body.documento.id, nombre)
                        //this.$refs.load.close();
                    } else {
                        axios({
                            url: '/pdf-report',
                            method: 'GET',
                            params: params
                        }).then(response => {
                            EventBus.$emit('open-informe', 'data:application/pdf;base64,'+response.data.body.base64, response.data.body.documento.id, nombre)
                            //this.$refs.load.close();
                        })
                    }
                }).catch(error => {
                    console.error('Error al descargar el archivo:', error);
                    EventBus.$emit('error-reporte', error.response)
                    //this.$refs.load.close();
                });
            },
            deleteItem(item) {
                switch (this.view) {
                    case 'asignacion':
                        this.$refs.dialogYesNoRef.open('Eliminar asignación', '¿Seguro que desea eliminar la asignación?', this.view, item)
                        break;
                    case 'asignacion-titular':
                        this.$refs.dialogYesNoRef.open('Eliminar asignación', '¿Seguro que desea eliminar la asignación?', this.view, item)
                        break;
                    case 'ficha-documentos':
                        this.$refs.dialogYesNoRef.open('Eliminar documento', '¿Seguro que desea eliminar el documento?', this.view, item)
                        break;
                    case 'compromiso-documento':
                        this.$refs.dialogYesNoRef.open('Eliminar documento', '¿Seguro que desea eliminar el documento?', this.view, item)
                        break;
                    case 'ficha-instrumento':
                        this.$refs.dialogYesNoRef.open('Eliminar documento', '¿Seguro que desea eliminar el documento?', this.view, item)
                        break;
                    default:
                        this.$refs.dialogYesNoRef.open('Eliminar compromiso', '¿Seguro que desea eliminar compromiso?', this.view, item)
                        break;
                }
                
            },
            newItem() {
                if (this.view == 'componente' || this.view == 'fase' || this.view == 'verificador' || this.view == 'frecuencia' || this.view == 'instrumento' || this.view == 'region' || this.view == 'comuna' || this.view == 'usuario' || this.view == 'estado' || this.view == 'codigo') {
                    let datos = this.mantenedorStore.getMantenedor(localStorage.getItem('mantenedor'))
                    this.$refs.formMantenedorRef.open(null, this.disabled, datos.url, datos.view)
                } else {
                    switch (this.view) {
                        case 'proyecto':
                            this.$refs.proyectoRef.open()
                            break;
                        case 'compromiso':
                            if (this.padreId == 0) {
                                this.$swal.fire({
                                    title: "Debe guardar la UF antes de crear un compromiso.",
                                    icon: 'warning',
                                    confirmButtonText: "Aceptar",
                                    confirmButtonColor: "#4caf50",
                                    allowOutsideClick: false,
                                    showCloseButton: true
                                })
                                break
                            } else {
                                var item = []
                                item.id = this.padreId
                                this.$refs.compromisoRef.open(item, false, '', this.totalCompromisos)
                                break;
                            }
                        case 'visita':
                            this.$refs.visitaRef.open()
                            break;
                        case 'contacto':
                            var item = []
                            item.titular_id = this.padreId
                            this.$refs.contactoRef.open(item)
                            break;
                        case 'ficha-bitacora':
                            var item = []
                            item.proyecto_id = this.padreId
                            this.$refs.bitacoraRef.open(item, false)
                            break;
                        case 'ficha-compromiso':
                            var item = []
                            item.id = this.padreId
                            this.$refs.compromisoRef.open(item, false, '', this.totalCompromisos)
                            break;
                        case 'compromiso-visita':
                            var item = []
                            item.id = this.padreId
                            this.$refs.compromisoRef.open(item, false, '', this.totalCompromisos)
                            break;
                        case 'usuario-titular':
                            this.$refs.usuarioTitularForm.open(item, false)
                            break;
                    }
                }
            },
            getSemaforo(item) {
                let texto = ''
                let completo = true
                let retorno = ''
                let es_cumple = false
                for (var key in item) {
                    var value = item[key];
                    if (key == 'estado' && value.id == this.commonStore.estadoCompromisoCumple) {
                        es_cumple = true
                    }
                    if (key != 'vencimiento' && key != 'estado' && key != 'observacion' && key != 'plan_accion' && key != 'cant_adj' && key != 'frecuencia' && key != 'responsable' && key != 'confirmacion_faltante') {
                        if (value == undefined || value == null || value == '') {
                            completo = false
                        }
                    }
                }
                switch (item['confirmacion_faltante']) {
                    case 'v':
                        if (texto == '') {
                            texto = '<p class="mt-4" style="margin-top: -10px; color: #ed7c44">Falta Verificador</p>'
                        } else {
                            texto = texto + '<p style="margin-top: -10px; color: #ed7c44">Falta Verificador</p>'
                        }
                        break;
                    case 'e':
                        if (texto == '') {
                            texto = '<p class="mt-4" style="margin-top: -10px; color: #ed7c44">Cambiar estado</p>'
                        } else {
                            texto = texto + '<p style="margin-top: -10px; color: #ed7c44">Cambiar estado</p>'
                        }
                        break;
                    default:
                        break;
                }
                
                if (!completo && !es_cumple) {
                    retorno = "<p class='mt-4' style='color: #ed7c44'>incompleto</p>"
                } else if (!completo && es_cumple && texto != '') {
                    retorno = "<p class='mt-4' style='color: #ed7c44'>incompleto</p>"
                    retorno = retorno + texto
                } else if (completo && es_cumple && texto != '') {
                    retorno = "<p class='mt-4' style='color: #388E3C'>completo</p>"
                    retorno = retorno + texto
                } else if (completo && !es_cumple) {
                    retorno = "<p class='mt-4' style='color: #388E3C'>completo</p>"
                } else if (completo && es_cumple && texto == '') {
                    retorno = ""
                } else if (!completo && es_cumple && texto == '') {
                    retorno = "<p class='mt-4' style='color: #ed7c44'>incompleto</p>"
                }
                return retorno
            },
            filtroColumna(value) {
                let contador = 0
                this.headers.forEach(header => {
                    if (header.value != 'actions' && header.value == value) {
                        header.filtro = !header.filtro
                        if (header.filtro == false){
                            this.filtroColumnas[value] = null
                        }
                    }
                    if (header.filtro == true) {
                        contador ++
                    }
                });

                if (contador > 0) {
                    this.mostrarfiltroColumna = true
                } else {
                    this.mostrarfiltroColumna = false
                }
            },
            async filtrarPorColumna(columna, valor, index) {
                this.isFiltroColumn = true
                this.rowsPerPage_ = -1
                await this.getItems('columna')
                /* let resultado = this.items.filter(objeto => {
                    if (columna.includes('.')) {
                        let variables = columna.split('.')
                        a = variables[0]
                        b = variables[1]
                        return objeto[a][b].includes(valor)
                    } else {
                        return objeto[columna].includes(valor)
                    }
                })
                this.items = resultado */
                const resultadoFiltrado = this.items.filter(objeto => {
                    return Object.keys(this.filtroColumnas).every(prop => {
                        const valorFiltro = this.filtroColumnas[prop].toLowerCase();
                        const valorObjeto = this.obtenerValor(objeto, prop);

                        if (valorObjeto === undefined || valorObjeto === null) {
                            return false;
                        }

                        if (!valorObjeto.toString().toLowerCase().includes(valorFiltro)) {
                            return false;
                        }
                        
                        return true;
                    });
                });
                this.items = resultadoFiltrado
                this.isFiltroColumn = false
            },
            obtenerValor(objeto, clave) {
                if (clave.includes('.')) {
                    const variables = clave.split('.');
                    let valor = objeto;
                    for (const variable of variables) {
                        valor = valor[variable];
                        if (valor === undefined || valor === null) {
                            return null;
                        }
                    }
                    return valor;
                } else {
                    return objeto[clave];
                }
            },
            getCategoriaEstado(item) {
                if (item.categoria == 'P') {
                    return 'Unidad Fiscalizable'
                } else if (item.categoria == 'C') {
                    return 'Compromiso'
                } else if (item.categoria == 'V') {
                    return 'Visita'
                }
            },
            cargarDocumentos(item) {
                let aux = item
                let disabled = false
                if (this.view == 'ficha-historico') {
                    aux.compromiso_id = this.padreId
                    disabled = true
                } else if (this.view == 'ejecuta-visita') {
                    aux.visita_id = this.padreId
                }
                this.$refs.documentoFormRef.open(aux, disabled);
            },
            nombreRca(value) {
                if(this.view == 'rca') {
                    if (value) {
                        const nombre = value.replace(/^\d+\s*-\s*/, ''); // Elimina el número y el guión al principio del texto
                        return nombre
                    } else {
                        return value
                    }
                } else {
                    if (value.includes('___')) {
                        value = value.split('___')[1]
                    }
                    return value
                }
            },
            openCompromiso(item) {
                this.$refs.compromisoRef.open(item, true, '', this.totalCompromisos)
            },
            valorSelectEstadoWrapper(item) {
                const refEstado = `cmbEstados${item.compromiso_id}`;
                const selectEstado = this.$refs[refEstado];
                this.estadoData[item.compromiso_id] = {valor: selectEstado.getValue(), id: item.compromiso_id}
            },
            setearValorSelect(item) {
                if (this.estadoData[item.compromiso_id] && this.estadoData[item.compromiso_id].valor) {
                    return this.estadoData[item.compromiso_id].valor
                } else {
                    return []
                }
            },
            valorTextArea(item, nombreRef) {
                switch (nombreRef) {
                    case 'taObservacion':
                        const refObservacion = `taObservacion${item.compromiso_id}`;
                        const taObservacion = this.$refs[refObservacion];
                        this.estadoObservacion[item.compromiso_id] = {valor: taObservacion.getValue(), id: item.compromiso_id}
                        break;
                    case 'taPlanAccion':
                        const refPlanAccion = `taPlanAccion${item.compromiso_id}`;
                        const taPlanAccion = this.$refs[refPlanAccion];
                        this.estadoPlanAccion[item.compromiso_id] = {valor: taPlanAccion.getValue(), id: item.compromiso_id}
                        break;
                }
            },
            setearValorTextArea(item, nombreRef) {
                switch (nombreRef) {
                    case 'taObservacion':
                        if (this.estadoObservacion[item.compromiso_id] && this.estadoObservacion[item.compromiso_id].valor) {
                            return this.estadoObservacion[item.compromiso_id].valor
                        }
                        return ''
                    case 'taPlanAccion':
                        if (this.estadoPlanAccion[item.compromiso_id] && this.estadoPlanAccion[item.compromiso_id].valor) {
                            return this.estadoPlanAccion[item.compromiso_id].valor
                        }
                        return ''
                }
            },
            seleccionColumnas(items) {
                this.keyTable++
                this.estadoData.forEach(estado => {
                    let index = this.estadoData.indexOf(estado);
                    if (index) {
                        let refEstado = `cmbEstados${estado.id}`
                        this.$nextTick(() => {
                            this.$refs[refEstado].setValue(estado.valor)
                        })
                    }
                })
                this.estadoObservacion.forEach(estadoObs => {
                    let index = this.estadoObservacion.indexOf(estadoObs);
                    if (index) {
                        let refObservacion = `taObservacion${estadoObs.id}`
                        this.$nextTick(() => {
                            this.$refs[refObservacion].setValue(estadoObs.valor)
                        })
                    }
                })
                this.estadoPlanAccion.forEach(estadoPlan => {
                    let index = this.estadoPlanAccion.indexOf(estadoPlan);
                    if (index) {
                        let refPlanAccion = `taPlanAccion${estadoPlan.id}`
                        this.$nextTick(() => {
                            this.$refs[refPlanAccion].setValue(estadoPlan.valor)
                        })
                    }
                })
            },
            actualizarCodigo(items) {
                for (let index = 0; index < items.length; index++) {
                    const element = items[index];
                    element.codigo = (index + 1).toString()
                }
                const data = {
                    compromisos: items
                }
                axios.put('actualizar/codigo', data).then((response) => {
                    this.snackbar = true;
                    this.texto = response.data.message
                    this.estado = response.data.code
                    this.items.sort((a, b) => a.codigo - b.codigo)
                    //this.getItems()
                }).catch((error) => {
                    console.error(error);
                })
            },
            debeMostrarColumna(clave) {
                const claves = this.selectedHeaders
                return claves.includes(clave);
            },
            async getEstados() {
                await axios.get("estados/categoria/C", {})
                .then(response => {
                    this.estados = response.data.body.items
                    //this.estados.push({id: 0, nombre: 'No verificado'})
                });
                if (this.filtroEstado) {
                    const index = this.estados.findIndex(
                        p => p.nombre.toUpperCase() === this.filtroEstado.toUpperCase()
                    );
                    this.$refs.sEstadoCompromiso.setValue(this.estados[index])
                    this.filtrosCompromisos()
                }
            },
            filtroRegistro(item) {
                const semaforo = this.getSemaforo(item);
                const semaforoText = semaforo.replace(/<[^>]+>/g, '').trim().toLowerCase();
                if (this.registroSeleccionado === null) {
                    return true;
                }
                return semaforoText === this.registroSeleccionado.toLowerCase();
            },
            changeRegistro() {
                this.items = this.itemsAux
                if(this.registroSeleccionado === null) {
                    this.items = this.itemsAux
                } else {
                    this.items = this.items.filter(element => {
                        let semaforo = this.getSemaforo(element)
                        return this.filtroRegistro(element)
                    })   
                }
            },
            reportabilidadNombre(item) {
                if(item) {
                    let sinEspacios = item.replace(/\s+/g, '');
                    let tamanio = sinEspacios.length
                    if (tamanio == 1) {
                        switch (sinEspacios) {
                            case 'R':
                                return 'Reportar a la autoridad'
                        
                            case 'M':
                                return 'Mantener registro en obra'
                        }
                    } else {
                        return 'Reportar a la autoridad /\nMantener registro en obra'
                    }
                }
            },
            copyItem(item) {
                item.id = this.padreId
                this.$refs.compromisoRef.open(item, false, '', this.totalCompromisos, true)
            },
            clickTitulares(item) {
                let headers = [
                    { text: 'Nombre', value: 'uf.nombre', filtro:false },
                    { text: 'Compromisos', value: 'compromisos', filtro:false, align: 'center' }
                ]
                this.$refs.dialogComponentRef.open(item.titular.nombre, '', headers, item.ufs)
            },
            toggleCheckboxDrag(item){
                this.selectedDragItems = this.items.filter(i => i.isSelected);
                this.selected = this.selectedDragItems
                this.showDeleteButton = this.selectedDragItems.length > 0;
            },
            async eliminarSeleccionCompromisos() {
                let ids = []
                if(this.view == 'compromiso') {
                    this.selectedDragItems.forEach(element => {
                        ids.push(element.compromiso_id)
                    })
                } else {
                    this.selected.forEach(element => {
                        ids.push(element.compromiso_id)
                    })
                }
                this.$swal.fire({
                    title: "¿Desea eliminar los compromisos seleccionados?",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: "Eliminar",
                    cancelButtonText: "Cancelar",
                    confirmButtonColor: "#4caf50",
                    allowOutsideClick: false,
                    showCloseButton: true
                }).then((result) => {
                    if( result.isConfirmed) {
                        axios.put('/compromisos/eliminar/masivo', {ids}).then(response => {
                            this.getItems()
                            this.keyTable++
                        }).catch( error => {
                            console.error(error)
                        })
                    } 
                })
            },
            onSelectionChange(){
                this.showDeleteButton = this.selected.length > 0;
            },
            toggleAllSelection() {
                if(this.allSelected) {
                    this.items.forEach(element => {
                        element.isSelected = true
                    });
                    this.selectedDragItems = this.items
                    this.showDeleteButton = true
                } else {
                    this.items.forEach(element => {
                        element.isSelected = false
                    });
                    this.selectedDragItems = []
                    this.showDeleteButton = false
                }
            },
            abrirVisita(item) {
                const visita_id = item.id
                this.$swal.fire({
                    title: "¿Está seguro de reabrir la visita?",
                    text: "Esta acción quedará registrada en el sistema",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: "Aceptar",
                    cancelButtonText: "Cancelar",
                    confirmButtonColor: "#4caf50",
                    allowOutsideClick: false,
                    showCloseButton: true
                }).then((result) => {
                    if( result.isConfirmed) {
                        const data = {
                            nuevo_estado: 15 //Enviado
                        }
                        axios.put('visita/estado/' + visita_id, data).then(response => {
                            this.getItems()
                            this.keyTable++
                        }).catch( error => {
                            console.error(error)
                        })
                    } 
                })
            },
            fechaValida(item) {
                if (item.fecha_cierre) {
                    const partesFecha = item.fecha_cierre.split('-');
                    const fechaCierre = new Date(`${partesFecha[2]}-${partesFecha[1]}-${partesFecha[0]}`);
                    const fechaActual = new Date();
                    const diferenciaMilisegundos = fechaActual - fechaCierre;
                    const diferenciaDias = diferenciaMilisegundos / (1000 * 60 * 60 * 24);
                    return diferenciaDias <= 7;
                } else {
                    return true
                }
            }
        }
    }
</script>
<style scoped>  
    .a-href {
        color:black
    }
    .rotate {
        transform: rotate(180deg);
    }
    ::v-deep .theme--light.v-data-table>.v-data-table__wrapper>table>thead>tr>th {
        background-color: #08697e; /* Cambia esto al color que desees */
        color: white; /* Cambia esto al color de texto que desees */
    }
    ::v-deep .active {
        background-color: #08697e !important; /* Cambia esto al color que desees */
        color: white !important; /* Cambia esto al color de texto que desees */
    }
    ::v-deep .my-table tbody tr:nth-child(even) {
        background-color: #d7ebfc; /* Color para filas pares */
    }
    ::v-deep .my-table tbody tr:nth-child(odd) {
        background-color: #ffffff; /* Color para filas impares */
    }
    .scrollable-div {
        max-height: 100px;
        overflow-y: auto; /* Esto permite el scroll vertical */
        max-width: 500px;
    }
    .loading-text {
        text-align: center;
        padding: 20px;
        font-size: 16px;
        color: #B0B0B0;
    }
</style>