<template>
  <v-row justify="end" class="mr-1">
    <div class="text-center">
    <v-dialog
      v-model="dialog"
      persistent
    >
      <v-card>
        <TitularForm ref="titularRef" @reloadForm="reloadForm"></TitularForm>
        <CompromisoForm ref="compromisoRef"></CompromisoForm>
        <BusquedaForm ref="busquedaRef"></BusquedaForm>
        <snackbar-component :snackbar.sync="snackbar" :texto="texto" :estado="estado"></snackbar-component>
        <v-toolbar flat class="dialog-toolbar">
          <v-toolbar-title class="text-h5 mt-4">Unidad Fiscalizable</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="cancelar">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text style="padding-bottom: 0px;">
          <v-form ref="form" v-model="valid" lazy-validation :disabled="disabled" class="mt-4">
            <v-container>
              <v-row>
                <v-col cols="4">
                  <text-field-component label="Nombre *" ref="pfnombre" :valor="nombre" required :disabled="disabled"></text-field-component>
                </v-col>
                <v-col cols="5" style="margin-top: -13px;">
                  <select-component label="RCA" ref="sRca" :urlExterna="urlRca" :multiple="true" :valorMultiple="rca" itemValue="id_expediente" itemText="nombre" @selected-rca="handleSelectedRca" :disabled="disabled"></select-component>
                </v-col>
                <!-- <v-col cols="3" style="margin-top: -18px; text-align: right">
                  <v-btn
                    v-if="!disabledBusqueda"
                    class="ma-2"
                    dark
                    color="green"
                    large
                    @click="busquedaDocumentos"
                  >
                    <v-icon left>
                      mdi-file-search
                    </v-icon>
                    Búsqueda en documentos
                  </v-btn>
                </v-col> -->
                <v-col cols="4 ml-0 mt-3">
                  <select-component label="Código DSS *" ref="sCodigoDss" :valor="codigoDss" url="codigos/A" itemText="codigo_largo" required :disabled="disabled"></select-component>
                </v-col>
                <v-col cols="5 ml-2 mt-6 mb-5">
                  <v-row>
                      <select-component :key="contador" label="Titular *" ref="pftitular" url="/titulares" :valor="titular" :editable="true" required :disabled="disabled"></select-component>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon class="ml-2 mb-4" @click="crearTitular()" :disabled="disabled" v-bind="attrs" v-on="on">
                            mdi-account-plus
                          </v-icon>
                        </template>
                      <span>Nuevo Titular</span>
                    </v-tooltip>
                  </v-row>
                </v-col>
                <v-col cols="2" class="ml-6">
                  <v-checkbox
                    label="Sin seguimiento"
                    v-model="seguimiento"
                  ></v-checkbox>
                </v-col>
                <v-col cols="4" class="mb-5">
                  <select-component label="Seleccione una region *" ref="sRegion" url="/regiones" :valor="region" @selected-region="handleSelectedRegion" type="regiones" required :disabled="disabled"></select-component>
                </v-col>
                <v-col cols="4" class="mb-5">
                  <select-component label="Seleccione una Comuna *" ref="sComuna" :url="'comunas/region/' + region_id" :valor="comuna" :key="keyComuna" required :disabled="disabled"></select-component>
                </v-col>
                <v-col cols="4" class="mb-5">
                  <select-component label="Seleccione una Fase *" ref="sFase" url="fases/" :valor="fase" required :disabled="disabled"></select-component>
                </v-col>
                <v-col cols="4">
                  <v-select :items="tipoGeo" v-model="selectGeo" label="Seleccione tipo de geolocalización" dense clearable :disabled="disabled"></v-select>
                </v-col>
                <template v-if="selectGeo == 'DIR'">
                  <v-col cols="8">
                    <text-field-component label="Dirección" ref="pfdireccion" :valor="direccion" required :disabled="disabled"></text-field-component>
                  </v-col>
                  <v-col cols="12">
                    <text-field-component label="Información adicional" ref="tfInformacionAdicional" :valor="informacionAdicional" :disabled="disabled"></text-field-component>
                  </v-col>
                </template>
                <template v-if="selectGeo == 'UTM' || selectGeo == 'DEC'">
                  <v-col :cols="selectGeo=='UTM' ? '2' : '4'" >
                    <text-field-component :label="selectGeo=='UTM' ? 'N' : 'Latitud'" ref="pflatitud" :valor="latitud" type="latitud" :tipoCoordenada="selectGeo" required :disabled="disabled"></text-field-component>
                  </v-col>
                  <v-col :cols="selectGeo=='UTM' ? '2' : '4'" >
                    <text-field-component :label="selectGeo=='UTM' ? 'E' : 'Longitud'" ref="pflongitud" :valor="longitud" type="longitud" :tipoCoordenada="selectGeo" required :disabled="disabled"></text-field-component>
                  </v-col>
                  <v-col cols="4" v-if="selectGeo == 'UTM'">
                    <select-component :data="zona" label="Seleccione la zona" ref="sZona" :valor="selectZona" required :disabled="disabled"></select-component>
                  </v-col>
                  <v-col cols="12">
                    <text-field-component label="Información adicional" ref="tfInformacionAdicional" :valor="informacionAdicional" :disabled="disabled"></text-field-component>
                  </v-col>
                </template>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="ma-2"
            large
            @click="cancelar"
          >
            Cerrar
          </v-btn>
          <v-btn
            v-if="!disabled"
            class="ma-2"
            dark
            color="cyan"
            large
            @click="guardarProyecto"
          >
            <v-icon left>
              mdi-content-save
            </v-icon>
            Guardar
          </v-btn>
        </v-card-actions>
        <v-card class="ml-4 mr-4" :key="disabledCompromisos" max-width="auto">
          <v-card-title>
            <span>Compromisos</span>
          </v-card-title>
          <v-card-text style="padding-bottom: 0px;">
            <v-col cols="12">
              <table-component class="ml-4" :headers="validarPerfil()" :url="url" view="compromiso" :disabled="disabledCompromisos" labelSearch="Búsqueda de compromiso" :key="keyTabla" :tableHeight=550 :padreId="proyecto_id" @tabla-cargada="suscribir" ref="tablaCompromiso" :disbleItemsPerPage="true" :disablePagination="true" :rowsPerPage=-1 :faseProp="fase" :filtroEstado="filtroEstado" :puedeEliminarCompromiso="puedeEliminarCompromiso"></table-component>
            </v-col>
          </v-card-text>
        </v-card>
        <v-card class="ml-4 mr-4 mt-3" max-height="440px" max-width="auto" style="overflow-y: auto; overflow-x: auto">
          <v-card-title>
            <span>RCA</span>
          </v-card-title>
          <v-card-text style="padding-bottom: 0px;">
            <v-col cols="12">
              <table-component class="ml-4" :headers="headers_rca" :data="rca" view="rca" labelSearch="Búsqueda de RCA" :key="keyTablaRca" :tableHeight=200 :showAdd="false"></table-component>
            </v-col>
          </v-card-text>
        </v-card>
      </v-card>
    </v-dialog>
  </div>
  </v-row>
</template>
<script>
  import axios from "axios";
  import TitularForm from "./TitularForm.vue";
  import CompromisoForm from "./CompromisoForm.vue";
  import { EventBus } from "@/event/event"
  import BusquedaForm from "./BusquedaForm.vue";
  import { perfilStore } from "@/store/PerfilStore"

  export default {
    components: {
      TitularForm,
      CompromisoForm,
      BusquedaForm
    },
    data: () => ({
      dialog: false,
      disabled: false,
      valid: true,
      nombre: '',
      rca: [],
      titular: null,
      headers_compromisos: [
        {text: '', value: '', width: '10px', filtro:false, sortable: false},
        {text: 'Registro', value: 'semaforo', width: '150px', filtro:false},
        {text: 'Acciones', value: 'actions', sortable: false, align: 'center', width: '160px', filtro:false},
        {text: 'Cod.', value: 'codigo', width: '80px', filtro:false, align: 'center'},
        {text: 'Nombre', value: 'nombre', width: '200px', filtro:false},
        {text: 'Descripción', value: 'descripcion', width: '500px', filtro:false},
        {text: 'Origen', value: 'origen', width: '200px', filtro:false}, 
        {text: 'Norma', value: 'norma', width: '200px', filtro:false}, 
        {text: 'Verificador', value: 'verificador', width: '200px', filtro:false},
        {text: 'Descripción del verificador', value: 'descripcion_verificador', width: '220px', filtro:false}, 
        {text: 'Componente', value: 'componente', width: '200px', filtro:false}, 
        {text: 'Fase', value: 'fase.nombre', width: '200px', filtro:false}, 
        {text: 'Frecuencia', value: 'frecuencia_sugerida.nombre', width: '200px', filtro:false}, 
        {text: 'Vencimiento', value: 'vencimiento', width: '200px', filtro:false},
        {text: 'Actualizado por', value: 'updatedby.name', width: '150px', filtro:false},
        {text: 'Estado', value: 'estado.nombre', width: '120px', filtro:false},
        {text: 'Observación ult. visita', value: 'obs_ult_visita', width: '400px', filtro:false},
        {text: 'Observación', value: 'observacion', width: '400px', filtro:false},
        {text: 'Reportabilidad', value: 'reportabilidad', width: '250px', filtro:false},
      ],
      headers_rca: [
        {text: 'Tipo de instrumento', value: 'tipo'},
        {text: 'Nombre', value: 'nombre'},
        {text: 'Número', value: 'numero'},
        {text: 'Detalle', value: 'url_doc'}
      ],
      proyecto_id: 0,
      keyTabla: 0,
      keyTable: 0,
      contador: 0,
      disabledCompromisos: false,
      documentos: [],
      keyDocs: 0,
      instrumentos: [],
      direccion: "",
      latitud: null,
      longitud: null,
      region: null,
      comuna: null,
      region_id: 0,
      keyComuna: 0,
      snackbar: false,
      texto: '',
      estado: 0,
      tipoGeo:[
        {text: 'Decimal', value: 'DEC'},
        {text: 'Dirección', value: 'DIR'},
        {text: 'UTM', value: 'UTM'}
      ],
      selectGeo: {text: '', value: ''},
      informacionAdicional: '',
      zona: [
        {nombre: '18', id: 18},
        {nombre: '19', id: 19}
      ],
      selectZona: [],
      estadoProyecto: '',
      codigoDss: '',
      url: '',
      keyTablaRca: 0,
      urlRca: process.env.VUE_APP_URL_RCA,
      disabledBusqueda: true,
      fase: [],
      seguimiento: false,
      filtroEstado: null,
      perfilStore: perfilStore(),
      perfil_id: null,
      puedeEliminarCompromiso: false
    }),
    methods: {
      open(item, disabled, opcion) {
        this.documentos = [new File([], '', { type: 'application/octet-stream' })];
        this.proyecto_id = 0;
        ++this.keyTabla;
        this.comuna = null
        this.url = ''
        this.rca = []
        this.keyTablaRca++
        if (item) {
          this.proyecto_id = item.id;
          this.url = '/compromisos/proyecto/' + this.proyecto_id + '/' + 'codigo'
          this.filtroEstado = opcion
          /* if (opcion) {
            switch (opcion) {
              case 'Cumple':
                this.url = '/compromisos/proyecto/' + this.proyecto_id + '/' + 'cumple'
                break;
              case 'No cumple':
                this.url = '/compromisos/proyecto/' + this.proyecto_id + '/' + 'no_cumple'
                break;
            }
          } */
          if (item.rca.length > 0) {
            this.disabledBusqueda = false
          } else {
            this.disabledBusqueda = true
          }
          this.disabledCompromisos = disabled;
          this.selectGeo = item.tipo_coordenada;
          if (this.$refs.form) {
            this.$refs.pfnombre.setValue(item.nombre);
            this.$refs.sRca.setValue(item.rca);
            this.rca = item.rca
            this.keyTablaRca++
            this.$refs.pftitular.setValue(item.titular);
            this.$refs.sCodigoDss.setValue(item.codigo_dss);
            this.$refs.sRegion.setValue(item.region);
            this.$refs.sComuna.setValue(item.comuna);
            this.$refs.sFase.setValue(item.fase)
            this.fase = item.fase
            this.seguimiento = item.sin_seguimiento;
            if (item.tipo_coordenada == 'DEC') {
              this.$nextTick(() => {
                this.$refs.pflatitud.setValue(item.latitud);
                this.$refs.pflongitud.setValue(item.longitud);
                this.$refs.tfInformacionAdicional.setValue(item.informacion_adicional);
              })
            } else if (item.tipo_coordenada == 'UTM') {
              this.$nextTick(() => {
                let arregloZona = {nombre: item.zona, id: item.zona}
                this.$refs.pflatitud.setValue(item.latitud);
                this.$refs.pflongitud.setValue(item.longitud);
                this.$refs.sZona.setValue(arregloZona);
                this.$refs.tfInformacionAdicional.setValue(item.informacion_adicional);
              })
            } else if ( item.tipo_coordenada == 'DIR'){
              this.$nextTick(() => {
                this.$refs.pfdireccion.setValue(item.direccion);
                this.$refs.tfInformacionAdicional.setValue(item.informacion_adicional);
              })
            }
          } else {
            this.nombre = item.nombre;
            this.rca = item.rca;
            this.keyTablaRca++
            this.titular = item.titular;
            this.direccion = item.direccion
            this.latitud = item.latitud
            this.longitud = item.longitud
            this.region = item.region
            this.comuna = item.comuna
            this.informacionAdicional = item.informacion_adicional
            if (item.zona) {
              let arregloZona = {nombre: item.zona, id: item.zona}
              this.selectZona = arregloZona
            }
            this.codigoDss = item.codigo_dss
            this.estadoProyecto = item.estado
            this.fase = item.fase
            this.seguimiento = item.sin_seguimiento
          }
          if (this.perfilStore.decryptData(localStorage.getItem('id')) == item.codigo_dss.responsable || this.perfilStore.decryptData(localStorage.getItem('id')) == item.codigo_dss.sub_responsable) {
            this.puedeEliminarCompromiso = true
          } else {
            this.puedeEliminarCompromiso = false
          }
        }

        if (this.perfilStore.decryptData(this.perfil_id) == this.perfilStore.administrador || 
            this.perfilStore.decryptData(this.perfil_id) == this.perfilStore.jefatura ||
            this.perfilStore.decryptData(this.perfil_id) == this.perfilStore.analista) {
          this.disabled = disabled;
        } else {
          this.disabled = true;
        }
        this.dialog = true;
      },
      getFileTypeFromExtension(filename) {
        const extension = filename.split('.').pop().toLowerCase();

        // Puedes agregar más extensiones y tipos de archivo según tus necesidades
        switch (extension) {
          case 'pdf':
            return 'application/pdf';
          case 'docx':
            return 'application/msword';
          case 'txt':
            return 'text/plain';
          case 'xlsx':
            return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
          default:
            return 'application/octet-stream';
        }
      },
      cancelar() {
        this.disabledBusqueda = true
        if (!this.disabled) {
          if (this.editado()) {
              this.$swal.fire({
                title: "¿Desea guardar los cambios efectuados?",
                icon: 'warning',
                showDenyButton: true,
                showCancelButton: true,
                confirmButtonText: "Guardar",
                denyButtonText: 'No guardar',
                cancelButtonText: "Cancelar",
                confirmButtonColor: "#4caf50",
                allowOutsideClick: false,
                showCloseButton: true
                }).then((result) => {
                  if (result.isConfirmed) {
                    this.guardarProyecto()
                    if (this.$refs.form.validate()) {
                      this.dialog = false
                    }
                  } else if (result.isDenied) {
                    this.$refs.form.reset();
                    this.$refs.form.resetValidation();
                    this.documentos = []
                    this.instrumentos = []
                    this.dialog = false
                    this.$emit('reload-ficha', this.proyecto_id);
                    this.refreshEditado()
                  }
                });
          } else {
            this.$refs.form.reset();
            this.$refs.form.resetValidation();
            this.documentos = []
            this.instrumentos = []
            this.fase = []
            this.dialog = false
            this.$emit('reload-ficha', this.proyecto_id);
          }
        } else {
          this.$refs.form.reset();
          this.$refs.form.resetValidation();
          this.documentos = []
          this.instrumentos = []
          this.fase = []
          this.dialog = false
          this.$emit('reload-ficha', this.proyecto_id);
        }
      },
      async guardarProyecto() {
        if (this.$refs.form.validate()) {
          let predeterminados = null
          if (this.proyecto_id === 0) {
            const result = await this.$swal.fire({
              title: "¿Desea crear compromisos predeterminados?",
              icon: 'info',
              showDenyButton: true,
              confirmButtonText: "Si",
              denyButtonText: 'No',
              confirmButtonColor: "#4caf50",
              allowOutsideClick: false,
              showCloseButton: true
            });
            predeterminados = result.value
          }
          
          //this.$refs.load.open();
          const data = new FormData();
          if(this.$refs.sRca.getValue().length > 0) {
            let aux = this.$refs.sRca.getValue();
            aux.forEach((element, index) => {
              data.append(`rca[${index}]`, JSON.stringify({
                'fecha': element.fecha,
                'folio': element.folio,
                'id_expediente': element.id_expediente,
                'numero': element.numero,
                'url_doc': element.url_doc,
                'nombre': element.nombre,
                'tipo': 'Resolución de Calificación Ambiental'
              }));
            });
          }
          data.append('nombre', this.$refs.pfnombre.getValue());
          data.append('titular', this.$refs.pftitular.getValue().id);
          data.append('tipo_coordenada', this.selectGeo);
          data.append('estado_id', /* this.$refs.sEstadoProyecto.getValue().id */ 10)
          data.append('predeterminados', predeterminados)
          data.append('codigo_id', this.$refs.sCodigoDss.getValue().id)
          const region_id = this.$refs.sRegion.getValue().id ? this.$refs.sRegion.getValue().id : []
          const comuna_id = this.$refs.sComuna.getValue().id ? this.$refs.sComuna.getValue().id : []
          const fase_id = this.$refs.sFase.getValue().id ? this.$refs.sFase.getValue().id : []
          data.append('region_id', region_id)
          data.append('comuna_id', comuna_id)
          data.append('fase_id', fase_id)
          data.append('seguimiento', this.seguimiento === true ? 'y' : 'n')
          if (this.selectGeo == 'DIR') {
            data.append('direccion', this.$refs.pfdireccion.getValue())
            data.append('informacion_adicional', this.$refs.tfInformacionAdicional.getValue())
          } else if (this.selectGeo == 'UTM') {
            data.append('latitud', this.$refs.pflatitud.getValue())
            data.append('longitud', this.$refs.pflongitud.getValue())
            data.append('informacion_adicional', this.$refs.tfInformacionAdicional.getValue())
            data.append('zona', this.$refs.sZona.getValue().id);
          } else if (this.selectGeo == 'DEC') {
            data.append('latitud', this.$refs.pflatitud.getValue())
            data.append('longitud', this.$refs.pflongitud.getValue())
            data.append('informacion_adicional', this.$refs.tfInformacionAdicional.getValue())
          }
          const config = {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          };
          if (this.proyecto_id === 0) {
            await axios.post('proyectos', data, config)
            .then(response => {
              this.proyecto_id = response.data.body.items.id;
              this.rca = response.data.body.items.instrumentos
              // Guardar documentos
              const data = new FormData();
              const config = {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              };
              this.url = '/compromisos/proyecto/' + this.proyecto_id + '/' + 'codigo'
              this.refresh(response)
              this.refreshEditado()
              //this.$refs.load.close();
            })
            .catch(error => {
              console.error(error);
              this.refresh(error.response)
              //this.$refs.load.close();
            });
          } else {
            data.append('_method', 'put');
            await axios.post(`proyectos/${this.proyecto_id}`, data, config)
            .then(response => {
              // Guardar documentos
              const data = new FormData();
              const config = {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              };
              this.rca = response.data.body.items.instrumentos
              this.refresh(response)
              this.refreshEditado()
              //this.$refs.load.close();
            }).catch(error => {
              console.error(error);
              this.refresh(error.response)
              //this.$refs.load.close();
            });
          }
        }
      },
      crearTitular() {
        this.$refs.titularRef.open()
      },
      reloadForm(value) {
        this.contador = value
      },
      addDocument(){
        const blobData = new Blob(['Hello, World!'], { type: 'text/plain' });
        const file = new File([blobData], '', { type: 'text/plain' });
        // Agrega el nuevo documento al array
        this.documentos.push(file);
      },
      removeDocument(index) {
        if (this.documentos.length > 0) {
          this.documentos.splice(index, 1);
          this.instrumentos.splice(index, 1);
        }
      },
      updateDocument(newFile, index) {
        this.$set(this.documentos, index, newFile);
      },
      handleSelectedValue(value) {
        this.instrumentos.push(value)
      },
      handleSelectedRegion(value){
        if (value) {
          this.region_id = value.id
          this.keyComuna++
        }
      },
      reloadTitular() {
        ++this.contador
      },
      refresh(response) {
        ++this.keyTabla
        ++this.keyTablaRca
        EventBus.$emit('refresh-table', response)
        EventBus.$off('refresh-table')
      },
      refreshEditado() {
        this.$refs.pfnombre.setEditado(false)
        this.$refs.pftitular.setEditado(false)
        this.$refs.sCodigoDss.setEditado(false)
        this.$refs.sRegion.setEditado(false)
        this.$refs.sComuna.setEditado(false)
        this.$refs.sRca.setEditado(false)
        if (this.selectGeo == 'DIR') {
          this.$refs.pfdireccion.setEditado(false)
          this.$refs.tfInformacionAdicional.setEditado(false)
        } else if (this.selectGeo == 'UTM') {
          this.$refs.pflatitud.setEditado(false)
          this.$refs.pflongitud.setEditado(false)
          this.$refs.sZona.setEditado(false)
          this.$refs.tfInformacionAdicional.setEditado(false)
        } else if (this.selectGeo == 'DEC') {
          this.$refs.pflatitud.setEditado(false)
          this.$refs.pflongitud.setEditado(false)
          this.$refs.tfInformacionAdicional.setEditado(false)
        }
      },
      editado() {
        if (this.$refs.pfnombre.getEditado() ||
          this.$refs.pftitular.getEditado() ||
          this.$refs.sCodigoDss.getEditado() ||
          this.$refs.sRegion.getEditado() ||
          this.$refs.sComuna.getEditado() ||
          (this.selectGeo == 'DIR' ? this.$refs.pfdireccion.getEditado() : false) ||
          (this.selectGeo == 'UTM' || this.selectGeo == 'DEC' ? this.$refs.pflatitud.getEditado() : false) ||
          (this.selectGeo == 'UTM' || this.selectGeo == 'DEC' ? this.$refs.pflongitud.getEditado() : false) ||
          (this.selectGeo == 'UTM' ? this.$refs.sZona.getEditado() : false) ||
          (this.selectGeo == 'UTM' || this.selectGeo == 'DEC' || this.selectGeo == 'DIR' ? this.$refs.tfInformacionAdicional.getEditado() : false) ||
          this.$refs.sRca.getEditado()) {
          return true
        } else {
          return false
        }
      },
      busquedaDocumentos() {
        const id_expediente = this.$refs.sRca.getValue()
        this.$refs.busquedaRef.open(id_expediente)
      },
      handleSelectedRca(value){
        if(value.length > 0) {
          this.disabledBusqueda = false
        } else {
          this.disabledBusqueda = true
        }
      },
      suscribir() {
        this.$refs.tablaCompromiso.suscribirse()
      },
      refreshCompromisos() {
        ++this.keyTabla
      },
      sortDate(a, b) {
          const dateA = this.parseDate(a);
          const dateB = this.parseDate(b);
          
          // Comparar las fechas
          if (dateA < dateB) return -1;
          if (dateA > dateB) return 1;
          return 0;
      },
      parseDate(dateString) {
          const [day, month, year] = dateString.split('-');
          // Crear una fecha en formato yyyy-MM-dd
          return new Date(`${year}-${month}-${day}`);
      },
      validarPerfil() {
        if (this.perfilStore.decryptData(this.perfil_id) != this.perfilStore.titular && this.perfilStore.decryptData(this.perfil_id) != this.perfilStore.encargadoTitular) {
          return this.headers_compromisos
        } else {
          let headers_compromisos_aux = this.headers_compromisos.filter(header => header.text !== 'Registro');
          return headers_compromisos_aux
        }
      }
    },
    mounted() {
      this.perfil_id = localStorage.getItem('perfil_id')
      EventBus.$on('reload-titular', (response) => {
        this.snackbar = true;
        this.texto = response.data.message
        this.estado = response.data.code
        ++this.contador;
      });
      EventBus.$on('refresh-compromisos', (response) => {
        this.refresh(response)
      });
      EventBus.$on('refresh-compromisos-uf', (response) => {
        this.refreshCompromisos()
        if (response) {
            this.snackbar = true;
            this.texto = response.data.message
            this.estado = response.data.code
        }
      });
    },
    beforeDestroy() {
      EventBus.$off('refresh-compromisos-uf', this.refreshCompromisos);
    }
  }
</script>
<style scoped>
  .margen-div {
    margin-top: -20px;
  }
  .dialog-toolbar {
    position: sticky;
    top: 0;
    background-color: white; /* Cambia esto al color que desees */
    z-index: 2;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
</style>