<template>
    <v-row justify="end" class="mr-1">
        <v-dialog
            v-model="dialog"
            persistent
            max-width="600px"
        >
            <v-card>
                <v-card-title>
                    <span class="text-h5">Usuarios Titular</span>
                </v-card-title>
                <v-card-text>
                    <v-form ref="form" v-model="valid" lazy-validation :disabled="disabled">
                        <v-container>
                            <v-row>
                                <v-col cols="12">
                                    <text-field-component label="Nombre *" ref="tfNombre" :valor="nombre" required :disabled="disabled"></text-field-component>
                                </v-col>
                                <v-col cols="12">
                                    <text-area-component label="Descripción" ref="tfDescripcion" :valor="descripcion" :counterValue="255"></text-area-component>
                                </v-col>
                                <v-col cols="12">
                                    <select-component label="Perfil *" ref="sPerfil" :data="perfiles" :valor="perfil" required :disabled="disabled"></select-component>
                                </v-col>
                                <v-col cols="12" class="mt-5">
                                    <text-field-component label="Email *" ref="tfEmail" :valor="email" type="email" required :disabled="disabled"></text-field-component>
                                </v-col>
                                <v-col cols="12">
                                    <text-field-component label="Contraseña *" ref="tfPassword" :valor="password" type="password" :disabled="disabled"></text-field-component>
                                </v-col>
                                <v-col cols="12">
                                    <select-component label="UF *" ref="sUF" :url="'/proyecto/titular/' + titular_id" :valorMultiple="unidades" requiredMultiple multiple :disabled="disabled"></select-component>
                                </v-col>
                                <v-col cols="2">
                                    <switch-component label="Estado" ref="swEstado" :valor="estado"></switch-component>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        class="ma-2"
                        large
                        @click="cancelar"
                    >
                        Cerrar
                    </v-btn>
                    <v-btn
                        v-if="!disabled"
                        class="ma-2"
                        dark
                        color="cyan"
                        large
                        @click="guardar"
                    >
                        <v-icon left>
                            mdi-content-save
                        </v-icon>
                        Guardar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
    import axios from 'axios';
    import { perfilStore } from '@/store/PerfilStore';
    import { EventBus } from "@/event/event"

    export default {
        data() {
            return {
                dialog: false,
                disabled: false,
                valid: true,
                nombre: '',
                descripcion: '',
                perfil: '',
                perfiles: [
                    {id: '4', nombre: 'Analista Titular'},
                    {id: '5', nombre: 'Encargado Titular'},
                ],
                email: '',
                password: '',
                estado: true,
                unidadesFiscalizables: [],
                unidades: [],
                perfilStore: perfilStore(),
                user_id: null,
                titular_id: 0,
                editarUsuario: false,
                idUsuarioEditable: null
            }
        },
        created() {
            this.user_id = this.perfilStore.decryptData(localStorage.getItem('id'))
        },
        methods: {
            async open(item, disabled) {
                await this.obtenerTitular()
                this.dialog = true
                this.disabled = disabled
                if (item) {
                    this.$nextTick(() => {
                        this.$refs.tfNombre.setValue(item.nombre)
                        this.$refs.tfDescripcion.setValue(item.descripcion)
                        switch (item.perfil.id) {
                            case 4:
                                this.$refs.sPerfil.setValue({id: '4', nombre: 'Analista Titular'})
                                break;
                            case 5:
                                this.$refs.sPerfil.setValue({id: '5', nombre: 'Encargado Titular'})
                                break;
                        }
                        this.$refs.tfEmail.setValue(item.email)
                        this.$refs.swEstado.setValue(item.activo)
                        this.$refs.sUF.setValue(item.proyectos)
                        this.$refs.tfPassword.setValue('')
                    })
                    this.editarUsuario = true
                    this.idUsuarioEditable = item.id
                }
            },
            cancelar() {
                this.$refs.form.reset();
                this.$refs.form.resetValidation();
                this.dialog = false;
                this.editarUsuario = false
            },
            guardar() {
                if (this.$refs.form.validate()) {
                    let email = this.$refs.tfEmail.getValue()
                    let perfil_id = this.$refs.sPerfil.getValue()
                    let password = ''
                    if (this.$refs.tfPassword.getValue() != '' && this.$refs.tfPassword.getValue() != null) {
                        password = this.perfilStore.encryptData(this.$refs.tfPassword.getValue())
                    }
                    let ufs = this.$refs.sUF.getValue()
                    let ufs_id = []
                    ufs.forEach(element => {
                        ufs_id.push(element.id);
                    });
                    const data = {
                        nombre: this.$refs.tfNombre.getValue(),
                        descripcion: this.$refs.tfDescripcion.getValue(),
                        perfil_id: perfil_id == 0 ? perfil_id : perfil_id.id,
                        email: email,
                        password: password,
                        activo: this.$refs.swEstado.getValue(),
                        ufs_id: ufs_id,
                        titular_id: this.titular_id
                    };
                    if (this.editarUsuario) {
                        axios.put('user' + '/' + this.idUsuarioEditable, data)
                        .then(response => {
                            EventBus.$emit('refresh-table', response)
                            this.afterService()
                        })
                        .catch(error => {
                            EventBus.$emit('refresh-table', error.response)
                            this.afterService()
                        });
                    } else {
                        axios.post('user', data).then(response => {
                            EventBus.$emit('refresh-table', response)
                            this.afterService()
                        }).catch(error => {
                            EventBus.$emit('refresh-table', error.response)
                            this.afterService()
                        });
                    }
                    
                }
            },
            async obtenerTitular() {
                await axios.get('/titular/asignado').then((response) => {
                    let titular = response.data.body.items
                    if (titular[0].id) {
                        this.titular_id = titular[0].id
                    }
                }).catch((error) => {
                    console.error(error)
                })
            },
            afterService() {
                //this.$refs.load.close();
                this.cancelar()
                const timestamp = new Date().getTime();
            }
        }
    }
</script>