import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/home",
    name: "home", 
    component: Home,
  },
  {
    path: "/",
    name: "login",
    component: Login,
  },
  {
    path: "/carga-datos",
    name: "carga-datos",
    component: () => import("@/views/CargaDatos"),
  },
  {
    path: "/registro-tabla",
    name: "registro-tabla",
    component: () => import("@/views/RegistroTabla"),
  },
  {
    path: "/mantenedores",
    name: "mantenedores",
    component: () => import("@/components/mantenedor/MantenedorComponent"),
  },
  {
    path: "/nuevo-compromiso",
    name: "nuevo-compromiso",
    props: true,
    component: () =>
      import(
        "@/views/formulario/CompromisoForm"
      ),
  },
  {
    path: "/nuevo-proyecto",
    name: "nuevo-proyecto",
    props: true,
    component: () =>
      import(
        "@/views/Proyecto"
      ),
  },
  {
    path: "/titular",
    name: "titular",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "nuevo-titular" */ "@/views/Titular"
      ),
  },
  {
    path: "/nueva-visita",
    name: "nueva-visita",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "nueva-visita" */ "@/views/Visita"
      ),
  },
  {
    path: "/calendario",
    name: "calendario",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "calendario" */ "@/components/calendario/Calendario"
      ),
  },
  {
    path: "/ficha",
    name: "ficha",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "ficha" */ "@/views/FichaProyecto"
      ),
  },
  {
    path: "/asignacion",
    name: "asignacion",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "asignacion" */ "@/components/mantenedor/AsignacionUf.vue"
      ),
  },
  {
    path: "/asignacion-formulario",
    name: "asignacion-formulario",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "asignacion-formulario" */ "@/components/mantenedor/AsignacionFormulario.vue"
      ),
  },
  {
    path: "/asignacion-titular",
    name: "asignacion-titular",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "asignacion-titular" */ "@/components/mantenedor/AsignacionTitular.vue"
      ),
  },
  {
    path: "/cambio-contrasena",
    name: "cambio-contrasena",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "cambio-contrasena" */ "@/views/CambioContrasena"
      ),
  },
  {
    path: "/usuario-titular",
    name: "usuario-titular",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "usuario-titular" */ "@/views/UsuarioTItular.vue"
      ),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

/* const router = createRouter({
  history: createWebHistory(),
  routes,
});
 */
export default router;
