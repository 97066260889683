<template>
  <v-app>
    <div id="app" data-app>
      <nav-bar></nav-bar>
      <!-- <section class="demo-content">
        <router-view />
      </section> -->
    </div>
  </v-app>
</template>

<script>
import NavBar from "./components/Nav.vue";

export default {
  name: "App",

  components: {
    NavBar
  },

  data: () => ({
    //
  }),

  methods: {
    isMobile() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        //console.log('mobile');
      } else {
        //console.log('desktop');
      }
    }
  },

  mounted() {
    this.isMobile();
  }
};
</script>
<style>
  .v-text-field--filled.v-input--dense.v-text-field--outlined.v-text-field--filled>.v-input__control>.v-input__slot,
  .v-text-field--filled.v-input--dense.v-text-field--outlined>.v-input__control>.v-input__slot,
  .v-text-field--filled.v-input--dense.v-text-field--single-line>.v-input__control>.v-input__slot,
  .v-text-field--full-width.v-input--dense.v-text-field--outlined.v-text-field--filled>.v-input__control>.v-input__slot,
  .v-text-field--full-width.v-input--dense.v-text-field--outlined>.v-input__control>.v-input__slot,
  .v-text-field--full-width.v-input--dense.v-text-field--single-line>.v-input__control>.v-input__slot,
  .v-text-field--outlined.v-input--dense.v-text-field--outlined.v-text-field--filled>.v-input__control>.v-input__slot,
  .v-text-field--outlined.v-input--dense.v-text-field--outlined>.v-input__control>.v-input__slot,
  .v-text-field--outlined.v-input--dense.v-text-field--single-line>.v-input__control>.v-input__slot {
      min-height: 20px !important; /* Cambia 40px a 20px o el valor que desees */
  }
  .div {
    min-height: 100%;
  }
  .theme--light.v-input--is-disabled, .theme--light.v-input--is-disabled input, .theme--light.v-input--is-disabled textarea {
    color: #797979 !important;
  }
  .theme--light.v-label--is-disabled {
    color: #797979 !important;
  }
</style>
